import React, { useEffect, useState } from 'react';
import ApplicationLeftBar from 'Components/DeveloperMode/ApplicationDashboard/ApplicationLeftBar';
import ApplicationNavbar from 'Components/DeveloperMode/ApplicationDashboard/ApplicatonNavbar';
import 'Styles/DeveloperMode/AppDashboard/AddApplication.css';
import ChoosePreference from 'Components/DeveloperMode/PopUpUtils/ChoosePreference';
import ApplicationMinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/ApplicationMinimizeLeftBar';
import Notfoundpage from 'Components/DeveloperMode/FlowDB/Notfoundpage';
import flowicon from 'Images/Add_flow_background.svg'
import {useNavigate } from 'react-router-dom';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';
const AddFlow = ({ onRefreshToken, onLogout, accessToken, minimize, setMinimize }) => {
    const [openForm, setOpenForm] = useState(false);
    const [newflow, setNewFlow] = useState(false)
    const [errorFlag, setErrorFlag] = useState(false);
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbarContext();

    useEffect(() => {
        if (errorFlag) {
            navigate("/");
            showSnackbar(`The server is currently down for maintenance.`, {
                variant: 'error',
                className: 'snackbar-error',
                autoHideDuration: 2500,
            });

            // Reset the error flag 
            setErrorFlag(false);
        }
    }, [errorFlag, navigate, showSnackbar]);

    const handlePreference = () => {
        setOpenForm(!openForm);
        setNewFlow(!newflow);
        localStorage.removeItem('flowName')
    }
    return (
        <div>
            {!accessToken ? <Notfoundpage /> :
                (<div className='appDashboard'>
                    <div className='sidebar'>
                        {minimize ? <ApplicationLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <ApplicationMinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}

                    </div>
                    <div className='content'>
                        <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                            <ApplicationNavbar />
                        </div>

                        <div className={`mainContentflow ${!minimize ? 'minimized' : ''}`}>
                            {minimize ?
                                <div className='newFlow'  >
                                    <div><img src={flowicon} alt='add flow' /></div>

                                    <div>
                                        <p id='addnewFlow1_P1'>No workflows found</p>
                                        <p id='addnewFlow1_P2'>Get started by adding a workflow to analyze security threats for</p>
                                    </div>
                                    <div className='addnewFlow1'>
                                        <button className='btnflow' onClick={handlePreference}>Add workflow</button>
                                    </div>
                                </div> :
                                <div className='newFlow-min' >
                                    <div><img src={flowicon} alt='add flow' /></div>
                                    <div>
                                        <p id='addnewFlow1_P1'>No workflows found</p>
                                        <p id='addnewFlow1_P2'>Get started by adding a workflow to analyze security threats for</p>
                                    </div>
                                    <div className='addnewFlow1'>
                                        <button className='btnflow' onClick={handlePreference}>Add workflow</button>
                                    </div>
                                </div>}

                        </div>
                        {openForm ? <ChoosePreference setErrorFlag={setErrorFlag} setOpenForm={setOpenForm} newflow={newflow} onRefreshToken={onRefreshToken} /> : ""}
                    </div>
                </div>)
            }
        </div>
    );
};

export default AddFlow;
