import React, { useState, useEffect } from 'react';
import 'Styles/DeveloperMode/ReportGeneration/CWEReport.css';
import ReportChart from 'Components/DeveloperMode/ReportContainer/ReportChart';
import back from 'Images/Previous.svg';
import { useNavigate } from 'react-router-dom';
import JsonViewer from 'Components/DeveloperMode/ReportContainer/JsonViewer';
import dataNotFound from 'Images/notDatafound.svg';
import zoomIn from 'Images/zoomIn.svg';
import { useWorkflowDetails } from 'Components/StateManagement/WorkflowDetailsContext';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';
import viewhatmlPage from 'Images/upcollapse.svg';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import exportPDF from 'Images/export.svg';
import EditIcon from '@mui/icons-material/Edit';

const CWEReport = ({setIsCheckRequest,setErrorFlag,setSendAttackResponse,setSendAttackRequest,setEditAttack, setViewHtml, setHtmlbody, completeReport, vulType, filteredData, setFilteredData, setReport_data, onRefreshToken, minimize, setCWEInfoName, setZoomFlag, setMaxFilteredData, setSelectApiFromReport, setPreventWorkflowRender }) => {

    // States to store different parts of the report data
    const [reportData, setReportData] = useState([]);
    const [statusCodeCounts, setStatusCodeCounts] = useState({});
    const [fetchError, setFetchError] = useState(false);
    const navigate = useNavigate();
    const [expandedApi, setExpandedApi] = useState(null);
    const [showOriginalFor, setShowOriginalFor] = useState({});
    const [selectedApi, setSelectedApi] = useState(0);
    const apiUrl = process.env.REACT_APP_URL;
    const { app_id, setApp_id } = useWorkflowDetails();
    const { flowId, setFlowId } = useWorkflowDetails();
    const { runId, setRunId } = useWorkflowDetails();
    const [accessTokenUpdated, setAccessTokenUpdated] = useState('');
    const { combineCWE, setCombineCWE } = useWorkflowDetails();
    const { showSnackbar } = useSnackbarContext();
    useEffect(() => {
        const storedFlowId = localStorage.getItem('flowId');
        const storedRunId = localStorage.getItem('runId');
        const storedApp_id = localStorage.getItem('app_id');
        const storeCombineCWE = sessionStorage.getItem('CWE-Name');
        if (storedApp_id) {
            setApp_id(storedApp_id);
        }
        if (storedFlowId) {
            setFlowId(storedFlowId);
        }
        if (storedRunId) {
            setRunId(storedRunId);
        }
        if (storeCombineCWE) {
            setCombineCWE(storeCombineCWE);
        }
    }, [app_id, flowId, runId, combineCWE]);



    useEffect(() => {
        if (app_id && flowId && runId && combineCWE) {
            fetchReportData();

            // if (completeReport !== "Fuzzing process completed" && (vulType === "All" || vulType === "Vulnerability")) {
            //     const interval = setInterval(fetchReportData, 3000);
            //     return () => clearInterval(interval); 
            // }
        } else {
            console.warn("Missing parameters :", { app_id, flowId, runId, combineCWE })
        }
    }, [app_id, flowId, runId, combineCWE]);

    // console.log(completeReport)

    useEffect(() => {
        if (accessTokenUpdated) {
            { fetchReportData(); }
        }
    }, [accessTokenUpdated]);


    const url = `${apiUrl}/reports/apps/${app_id}/flows/${flowId}/runs/${runId}/cwe/${combineCWE}/latest`;

    const fetchReportData = async () => {
        try {
            const localToken = localStorage.getItem('accessToken');
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();

                setPreventWorkflowRender(true);
                if (data && data.length > 0) {
                    const trimmedData = data.map((report) => {
                        return {
                            report_id: report.report_info ? report.report_info[Object.keys(report.report_info)[0]].report_id : "",
                            cwe_info: report.report_info ? report.report_info[Object.keys(report.report_info)[0]].cwe_info : "",
                            creation_time: report.report_info ? report.report_info[Object.keys(report.report_info)[0]].creation_time : "",
                            last_updated_time: report.report_info ? report.report_info[Object.keys(report.report_info)[0]].last_updated_time : "",
                            report_data: report.report_info ? report.report_info[Object.keys(report.report_info)[0]].report_data : []
                        };
                    })
                    setReportData((trimmedData));
                    setFilteredData(trimmedData);
                    setMaxFilteredData(trimmedData); //trimmed matadata
                    setReport_data(trimmedData);

                    const requests = [];
                    const responses = [];
                    const attacks = [];
                    const attackReqs = [];
                    const attackResps = [];
                    const cweInfos = [];
                    const modComponents = [];

                    trimmedData.forEach((report) => {
                        report.report_data.forEach((item) => {
                            // Extract Original_Request and Original_Response
                            if (item.Original_Request) {
                                requests.push(item.Original_Request);
                            }
                            if (item.Original_Response) {
                                responses.push(item.Original_Response);
                            }

                            //Extract Attack_info
                            if (item.Attack_info && item.Attack_info.length > 0) {
                                attacks.push(item.Attack_info);
                                item.Attack_info.forEach((attack) => {
                                    if (attack.Attack_request) {
                                        attackReqs.push(attack.Attack_request);
                                    }
                                    if (attack.Attack_response) {
                                        attackResps.push(attack.Attack_response);
                                    }

                                    //Extract modified data 
                                    if (attack.Attack_request && attack.Attack_request.Modified_data) {
                                        const modifiedData = attack.Attack_request.Modified_data;
                                        if (modifiedData.CWE_info) {
                                            cweInfos.push(modifiedData.CWE_info);
                                        }
                                        if (modifiedData.Modified_components) {
                                            modComponents.push(modifiedData.Modified_components);
                                        }
                                    }
                                });
                            }
                        });
                    });

                    const cweInfoArray = trimmedData.map(item => item.cwe_info);
                    // setCWEInfoName(cweInfoArray);
                    // Check if the array contains only null values or is empty
                    if (cweInfoArray.length === 0 || cweInfoArray.every(item => item === null)) {
                        setCWEInfoName(['All Risks']);
                    } else {
                        setCWEInfoName(cweInfoArray);
                    }

                    //function call for extracting the status code
                    calculateStatusCodeCounts(trimmedData);
                }
            }
            else if (response.status === 400) {
                setFetchError(true);
                showSnackbar(`Bad Request`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })

                console.error('Bad Request:', response.statusText);
            } else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            }
            else if (response.status === 500) {
                showSnackbar(`Internal Server Error`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })
            }
            else {
                console.error('Error fetching JSON data:', response.statusText);
            }
        } catch (error) {
            console.log("Error in fetching the perticular CWE report", error);
            if (error.message.includes("ERR_CONNECTION_REFUSED") || error.message.includes("Failed to fetch")) {
                setErrorFlag(true);
            }
            setFetchError(true);
        }
    }

    useEffect(() => {
        if (filteredData) {
            calculateStatusCodeCounts(filteredData, vulType);
        }
    }, [filteredData, vulType])


    //collect all status code present in original resposne and attack response
    const calculateStatusCodeCounts = (data, vulType) => {
        const counts = {};
        data.forEach((report) => {
            report.report_data.forEach((item) => {
                //count for original response code
                if (vulType === 'All' || vulType === 'Vulnerability' && item.Original_Response && item.Original_Response.status_code) {
                    counts[item.Original_Response.status_code] = (counts[item.Original_Response.status_code] || 0) + 1;
                }

                //count for attack response code
                if (item.Attack_info && item.Attack_info.length > 0) {
                    item.Attack_info.forEach((attack) => {
                        if (attack.Attack_response && attack.Attack_response.status_code) {
                            counts[attack.Attack_response.status_code] = (counts[attack.Attack_response.status_code] || 0) + 1;
                        }
                    });
                }
            });
        });
        //update the state of status code
        setStatusCodeCounts(counts);
    };


    const toggleShowOriginals = (apiIndex) => {
        setShowOriginalFor((prevState) => ({
            ...prevState,
            [apiIndex]: !prevState[apiIndex],
        }));
    };
    const handleApiClick = (apiData, apiIndex) => {
        // console.log(apiIndex)
        setSelectedApi(apiIndex);
        setSelectApiFromReport(apiIndex)
        setExpandedApi(null); // Reset expanded state for consistency
    };
    const handleZoom = () => {
        setZoomFlag(true);
    }
    const viewHtml = (body) => {
        // console.log(body)
        setHtmlbody(body)
        setViewHtml(true);
    }

    const convertIST = (timestamp, updatedTime) => {
        if (!timestamp) return null;
        const date = new Date(timestamp);

        return date.toLocaleString('en', {
            // timeZone: 'Asia/Kolkata',
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });
    }
     const downloadPDF = (data) => {
            const doc = new jsPDF();
    
            data.forEach((report, idx) => {
    
                // Add report metadata
                doc.setFontSize(14);
                doc.setFont("helvetica", "bold");
                doc.text(`${report.cwe_info}`, 10, 10);
                doc.setFontSize(12);
                doc.setFont("helvetica", "normal");
                doc.text(`Created: ${convertIST(report.creation_time)}`, 10, 20);
                doc.text(`Updated: ${convertIST(report.last_updated_time)}`, 10, 30);
    
                // Prepare rows for the table
                const tableRows = [];
                const tableHeaders = ["Key", "Value"]; // Two-column layout
    
                report.report_data.forEach((reportData) => {
                    // Original Request and Response
                    tableRows.push([
                        "Original Request",
                        JSON.stringify(reportData.Original_Request, null, 2),
                    ]);
                    tableRows.push([
                        "Original Response",
                        JSON.stringify(reportData.Original_Response, null, 2),
                    ]);
    
                    reportData.Attack_info.forEach((attackInfo, attackIdx) => {
                        // Add Analysis Bucket above Attack Requests and Responses
                        tableRows.push([
                            `Analysis Bucket (${attackIdx + 1})`,
                            attackInfo.analysis_bucket,
                        ]);
    
                        // Attack Request and Response
                        tableRows.push([
                            "Attack Request",
                            JSON.stringify(attackInfo.Attack_request, null, 2),
                        ]);
                        tableRows.push([
                            "Attack Response",
                            JSON.stringify(attackInfo.Attack_response, null, 2),
                        ]);
                    });
                });
    
                // Add the table using autotable
                doc.autoTable({
                    startY: 40, // Start below the metadata
                    head: [tableHeaders],
                    body: tableRows,
                    styles: { cellPadding: 2, fontSize: 10 },
                    theme: "grid",
                    columnStyles: {
                        0: { cellWidth: 40 }, // Key column
                        1: { cellWidth: 145 }, // Value column
                    },
                });
    
                // Add a new page for the next report if there is more data
                if (idx < data.length - 1) {
                    doc.addPage();
                }
            });
    
            // Save the PDF
            doc.save(`Report_Data.pdf`);
        };
    
    // console.log(JSON.stringify(reportData))
    const sendData=(attackDataRequest,attackDataResponse,flag)=>{
       if(flag){
        setIsCheckRequest(true);
       }else{
        setIsCheckRequest(false)
       }
       
        setEditAttack(true);
        setSendAttackRequest(attackDataRequest)
        setSendAttackResponse(attackDataResponse)

    }
    return (
        <div >
            {fetchError ?
                <div className={`empty-report ${minimize ? 'img' : 'none'}`}><p className='empty-msg'>Report is not generated. Please start the fuzzer or wait for a response</p></div>
                :
                (
                    reportData && reportData.length > 0 ? (
                        <div className='report-container'>
                            <div className='report-header'>
                                <div>
                                    {reportData.map((item, idx) => (
                                        <div key={idx} style={{ display: 'flex', gap: '10px' }}>
                                            <img src={back} alt='backbutton' onClick={() => navigate(-1)} style={{ cursor: 'pointer', padding:'5px 5px 5px 5px',marginTop:'-1px' }} />
                                            <div style={{marginLeft:'-5px'}}>{item.cwe_info ? <div>{item.cwe_info}</div> : <div>{"All Risks"}</div>}</div>
                                        </div>
                                    ))}
                                </div>
                                {/* <div className="dropdownVul">
                                    <CustomDropdown
                                        options={vulOptions}
                                        selectedOption={selectedVulType}
                                        onSelect={handleVulTypeSelection}
                                    />
                                </div> */}
                                {filteredData.length === 1 ?
                                <button onClick={() => downloadPDF(filteredData)} style={{ boxSizing: 'border-box', border: 'none', backgroundColor: '#f6f6f6' }}>
                                    <img src={exportPDF} alt='download pdf' style={{ width: '20px', marginLeft: '5px' }} />
                                </button>:''}
                            </div>
                            {filteredData.length === 1 ?

                                (<section className='report-section' >
                                    <div className='graphical-data'>
                                        <div id='res-code'>Response Code</div>
                                        <div id='donut-graph'><ReportChart statusCodeCounts={statusCodeCounts} /></div>

                                        <div className='show-apis'>
                                            {filteredData.map((reportItem, reportIndex) => (
                                                <div key={reportIndex} className='cwe-container'>
                                                    {reportItem.report_data.map((apiData, apiIndex) => {
                                                        const originalRequestUrl = apiData.Original_Request?.url || 'N/A';
                                                        const attackInfoCount = apiData.Attack_info?.length || 0;
                                                        // const trimmedURL=originalRequestUrl.slice(-30);
                                                        return (
                                                            <div
                                                                className={`selected-api ${selectedApi === apiIndex ? 'expanded' : ''}`}
                                                                style={{ backgroundColor: selectedApi === apiIndex ? '#FFCB2D' : '#fff', border: selectedApi === apiIndex ? '1px solid #FFCB2D' : '' }}
                                                                key={apiIndex}
                                                                onClick={() => handleApiClick(apiData, apiIndex)}
                                                            >
                                                                <div className='dropdown-title'>
                                                                    <div id='api-headerName'>
                                                                    <span >{apiIndex +1}</span>  {originalRequestUrl.length > 20 ? `...${originalRequestUrl.slice(-20)}` : originalRequestUrl}
                                                                    </div>

                                                                    <div id='attack-count' style={{ marginLeft: '10px', fontSize: '14px', color: '#555' }}>
                                                                        ({attackInfoCount} {attackInfoCount === 0 ? "Attack" : "Attacks"})
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                        </div>

                                    </div>

                                    <div className='api-dropdown'>
                                        {selectedApi === null ? <div className='initial-report'><img src={dataNotFound} alt='data not found' /></div> : ""}
                                        {selectedApi !== null && filteredData.map((reportItem) => (
                                            <div key={selectedApi} className='cwe-container'>
                                                {reportItem.report_data.map((apiData, apiIndex) => {
                                                    if (apiIndex !== selectedApi) return null; // Only show details for selected API

                                                    const showOriginal = showOriginalFor[apiIndex] || false;
                                                    const originalRequestUrl = apiData.Original_Request?.url || 'N/A';

                                                    return (
                                                        <div key={apiIndex} className='cwe-attack-expanded'>

                                                            <div className='spit-data'>
                                                                <div className='dropdown-header'>
                                                                    <div id='api-headerName'>
                                                                        {originalRequestUrl.length > 75 ? `${originalRequestUrl.slice(0, 75)}...` : originalRequestUrl}
                                                                    </div>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                                        <div className='checkbox-container'>
                                                                            <input
                                                                                style={{ cursor: "pointer" }}
                                                                                type='checkbox'
                                                                                id={`checkbox-${apiIndex}`}
                                                                                checked={showOriginal}
                                                                                onChange={(e) => {
                                                                                    e.stopPropagation();
                                                                                    toggleShowOriginals(apiIndex);
                                                                                }}
                                                                            />
                                                                            <label htmlFor={`checkbox-${apiIndex}`} className='checkbox-label'>Show Attack Data</label>
                                                                        </div>
                                                                        <div style={{ marginLeft: '10px', border: '1px solid #383838', height: '25px', borderRadius: '5px' }} ><img src={zoomIn} alt='Zoom In' style={{ marginTop: '0px', width: '25px', height: '25px' }} onClick={() => { handleZoom() }} /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Original Request and Response */}
                                                            <div className='original-request-response-container'>
                                                                <div className='original-request-box'>
                                                                    <div className='attack-header' id='attack-header' style={{display:'flex',justifyContent:'space-between'}}>
                                                                        <div>Original Request</div>
                                                                        <div onClick={()=>sendData(apiData.Original_Request,apiData.Original_Response,true)} style={{marginRight:'8px'}}><EditIcon sx={{width:'25px',height:'15px',cursor:'pointer',marginTop:'-5px'}}/></div>

                                                                    </div>
                                                                    <div className='custom-line'> </div>
                                                                    <JsonViewer data={apiData.Original_Request} />
                                                                </div>
                                                                <div className='original-response-box'>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <div id='attack-header'>Original Response</div>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer',height:'10px'}} onClick={() => viewHtml(apiData.Original_Response.body)}>
                                                                            <div className='htmlView'>HTML View</div>
                                                                            <img src={viewhatmlPage} alt='view page' className='viewPage' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='custom-line'> </div>
                                                                    <JsonViewer data={apiData.Original_Response} />
                                                                </div>
                                                            </div>

                                                            {showOriginal && apiData.Attack_info.map((attack, attackIndex) => (
                                                                <div key={attackIndex} className='request-response-container'>
                                                                    <div className='request-box'>
                                                                        <div id='attack-header' style={{display:'flex',justifyContent:'space-between'}}>
                                                                            <div>Attack Request</div>
                                                                            <div onClick={()=>sendData(attack.Attack_request,attack.Attack_response)} style={{marginRight:'8px'}}><EditIcon sx={{width:'25px',height:'15px',cursor:'pointer',marginTop:'-5px'}}/></div>
                                                                        </div>

                                                                        <div className='custom-line'> </div>
                                                                        <JsonViewer data={attack.Attack_request} />
                                                                    </div>
                                                                    <div className='response-box'>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <div id='attack-header'>Attack Response</div>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer',height:'10px'}} onClick={() => viewHtml(attack.Attack_response.body)}>
                                                                                <div className='htmlView'>HTML View</div>
                                                                                <img src={viewhatmlPage} alt='view page' className='viewPage' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='custom-line'> </div>
                                                                        <JsonViewer data={attack.Attack_response} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </div>

                                </section>)

                                : (
                                    <div><img src={dataNotFound} alt='data not found' className='empty-report-page' /></div>
                                )
                            }
                        </div>
                    ) : (
                        <div className={`No-report ${minimize ? 'img' : 'none'}`}> </div>
                    )
                )}
        </div>
    );
};

export default CWEReport;
