import React, { useEffect, useState } from 'react'
import 'Styles/DeveloperMode/AppDashboard/FlowsContainer.css';
import LeftSidebar from 'Components/DeveloperMode/LeftSidebar/LeftSidebar';
import 'Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import MinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/MinimizeLeftBar';
import CWEReport from 'Components/DeveloperMode/ReportContainer/CWEReport';
import ZoomInReport from 'Components/DeveloperMode/ReportContainer/ZoomInReport';
import ReportNavbar from 'Components/DeveloperMode/MainContent/ReportNavbar';
import { useWorkflowDetails } from 'Components/StateManagement/WorkflowDetailsContext';
import ViewHtml from 'Components/DeveloperMode/ReportContainer/ViewHtml';
import DOMPurify from 'dompurify';
import EditAttack from 'Components/DeveloperMode/ReportContainer/EditAttack';
import { useNavigate } from 'react-router-dom';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';

const ReportContainer = ({ completeReport, onLogout, onRefreshToken, minimize, setMinimize, setPreventWorkflowRender, preventWorkflowRender }) => {
    const [cweInfoName, setCWEInfoName] = useState('');
    const [zoomFlag, setZoomFlag] = useState(false);
    const [selectApiFromReport, setSelectApiFromReport] = useState(0);
    const [maxFilteredData, setMaxFilteredData] = useState([]);
    const [report_data, setReport_data] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const { combineCWE } = useWorkflowDetails();
    const [viewHtml, setViewHtml] = useState(false);
    const [vulType, setVulType] = useState('Vulnerability')
    const [htmlBody, setHtmlbody] = useState('');
    const [editAttack, setEditAttack] = useState(false);
    const [sendAttackRequest, setSendAttackRequest] = useState([]);
    const [sendAttackResponse, setSendAttackResponse] = useState([]);
    const clean = DOMPurify.sanitize(htmlBody);
    const [errorFlag, setErrorFlag] = useState(false);
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbarContext();
    const [isCheckRequest, setIsCheckRequest] = useState(false);

    useEffect(() => {
        if (errorFlag) {
            navigate("/");
            showSnackbar(`The server is currently down for maintenance.`, {
                variant: 'error',
                className: 'snackbar-error',
                autoHideDuration: 2500,
            });

            // Reset the error flag 
            setErrorFlag(false);
        }
    }, [errorFlag, navigate, showSnackbar]);

    return (

        <div className='appDashboard'>
            {/* {!zoomFlag ? */}
            <>
                <div className='sidebar'>
                    {minimize ? <LeftSidebar
                        onLogout={onLogout}
                        setMinimize={setMinimize}
                        minimize={minimize}
                        preventWorkflowRender={preventWorkflowRender}
                    /> :
                        <MinimizeLeftBar
                            onLogout={onLogout}
                            setMinimize={setMinimize}
                            minimize={minimize}
                            preventWorkflowRender={preventWorkflowRender}

                        />}
                </div>
                <div className='content'>
                    <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                        {/* <Navbar minimize={minimize} cweInfoName={cweInfoName} combineCWE={combineCWE}/> */}
                        <ReportNavbar
                            cweInfoName={cweInfoName}
                            combineCWE={combineCWE}
                            setMaxFilteredData={setMaxFilteredData}
                            report_data={report_data}
                            setFilteredData={setFilteredData}
                            setVulType={setVulType}
                        />
                    </div>
                    <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
                        <CWEReport
                            onRefreshToken={onRefreshToken}
                            minimize={minimize}
                            setCWEInfoName={setCWEInfoName}
                            setZoomFlag={setZoomFlag}
                            setSelectApiFromReport={setSelectApiFromReport}
                            setMaxFilteredData={setMaxFilteredData}
                            setPreventWorkflowRender={setPreventWorkflowRender}
                            setReport_data={setReport_data}
                            setFilteredData={setFilteredData}
                            filteredData={filteredData}
                            vulType={vulType}
                            completeReport={completeReport}
                            setViewHtml={setViewHtml}
                            setHtmlbody={setHtmlbody}
                            setSendAttackRequest={setSendAttackRequest}
                            setEditAttack={setEditAttack}
                            setSendAttackResponse={setSendAttackResponse}
                            setErrorFlag={setErrorFlag}
                            setIsCheckRequest={setIsCheckRequest}
                        />
                    </div>
                    {zoomFlag &&
                        <ZoomInReport
                            setZoomFlag={setZoomFlag}
                            selectApiFromReport={selectApiFromReport}
                            maxFilteredData={maxFilteredData}
                            setViewHtml={setViewHtml}
                            setHtmlbody={setHtmlbody}
                            setSendAttackRequest={setSendAttackRequest}
                            setEditAttack={setEditAttack}
                            setSendAttackResponse={setSendAttackResponse}
                        />
                    }


                </div>
            </>
            {viewHtml &&
                <ViewHtml
                    htmlContent={clean}
                    setViewHtml={setViewHtml}
                />
            }
            {editAttack &&
                <EditAttack
                    editAttack={editAttack}
                    setEditAttack={setEditAttack}
                    sendAttackRequest={sendAttackRequest}
                    sendAttackResponse={sendAttackResponse}
                    isCheckRequest={isCheckRequest} />
            }
        </div>

    )
}

export default ReportContainer
