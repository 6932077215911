import React, { useState } from 'react';
import JsonViewer from './JsonViewer';
import dataNotFound from 'Images/notDatafound.svg';
import 'Styles/DeveloperMode/ReportGeneration/ZoomInReport.css';
import zoomOut from 'Images/zoomOut.svg';
import viewhatmlPage from 'Images/upcollapse.svg';
import EditIcon from '@mui/icons-material/Edit';

const ZoomInReport = ({setIsCheckRequest,setSendAttackResponse,setSendAttackRequest,setEditAttack, setViewHtml, setHtmlbody, selectApiFromReport, maxFilteredData, setZoomFlag }) => {
    const [showOriginalFor, setShowOriginalFor] = useState({});


    const toggleShowOriginals = (apiIndex, apiData) => {
        const hasAttackInfo = apiData.Attack_info && apiData.Attack_info.length > 0;

        setShowOriginalFor((prevState) => ({
            ...prevState,
            [apiIndex]: hasAttackInfo ? !prevState[apiIndex] : false,
        }));
    };

    const handleZoom = () => {
        setZoomFlag(false);
    };
    const viewHtml = (body) => {
        // console.log(body)
        setHtmlbody(body)
        setViewHtml(true);
    }
    const sendData=(attackDataRequest,attackDataResponse,flag)=>{
        if(flag){
         setIsCheckRequest(true);
        }else{
         setIsCheckRequest(false)
        }
        
         setEditAttack(true);
         setSendAttackRequest(attackDataRequest)
         setSendAttackResponse(attackDataResponse)
 
     }
    return (
        <div className={`zoom-report ${selectApiFromReport !== null ? 'show' : ''}`}>
            <div className='zoomApi-dropdown'>
                {selectApiFromReport === null ? (
                    <div className='initial-report-notFound'>
                        <img src={dataNotFound} alt='data not found' />
                    </div>
                ) : (
                    maxFilteredData.map((reportItem) => (
                        <div key={selectApiFromReport} className='cwe-container'>
                            {reportItem.report_data.map((apiData, apiIndex) => {
                                if (apiIndex !== selectApiFromReport) return null;

                                const showOriginal = showOriginalFor[apiIndex] || false;
                                const originalRequestUrl = apiData.Original_Request?.url || 'N/A';
                                console.log(showOriginal, "showOriginal")

                                return (
                                    <div key={apiIndex} className='cwe-attack-expanded'>
                                        <div className='spit-data-zoomIn'>
                                            <div className='dropdown-header-zoomIn'>
                                                <div id='api-headerName-zoomIn'>
                                                    {originalRequestUrl.length > 75
                                                        ? `${originalRequestUrl.slice(0, 75)}...`
                                                        : originalRequestUrl}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className='checkbox-container-zoomIn'>
                                                        <input
                                                            style={{ cursor: 'pointer' }}
                                                            type='checkbox'
                                                            id={`checkbox-${apiIndex}`}
                                                            // checked={showOriginal}
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                toggleShowOriginals(apiIndex, apiData);
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor={`checkbox-${apiIndex}`}
                                                            className='checkbox-label-header'
                                                        >
                                                            Show Attack Data
                                                        </label>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '10px',
                                                            border: '1px solid #383838',
                                                            height: '25px',
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        <img
                                                            src={zoomOut}
                                                            alt='Zoom In'
                                                            style={{
                                                                marginTop: '0px',
                                                                width: '25px',
                                                                height: '25px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                handleZoom();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div style={{border:'1px solid #fff'}} className='overflow-report'>               */}
                                        <div className={`original-request-response-container-zoomIn ${showOriginal ? 'reduced-height' : ''}`} >
                                            <div className={`original-request-box-zoomIn ${showOriginal ? 'reduced-height-req' : ''}`}>
                                                <div className='attack-header-zoomIn' id='attack-header-zoomIn'  style={{display:'flex',justifyContent:'space-between'}} > 
                                                    <div>Original Request</div>
                                                    <div onClick={()=>sendData(apiData.Original_Request,apiData.Original_Response,true)} style={{marginRight:'8px'}}><EditIcon sx={{width:'25px',height:'15px',cursor:'pointer',marginTop:'-5px'}}/></div>
                                                    </div>
                                                <div className='custom-line'> </div>
                                                <JsonViewer data={apiData.Original_Request} />
                                            </div>
                                            <div className={`custom-line-vertical ${showOriginal ? 'reduced-height-line' : ''}`}  > </div>
                                            <div className={`original-response-box-zoomIn ${showOriginal ? 'reduced-height-res' : ''}`} >
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                    <div id='attack-header-zoomIn'>Original Response</div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => viewHtml(apiData.Original_Response.body)}>
                                                        <div className='htmlView'>HTML View</div>
                                                        <img src={viewhatmlPage} alt='view page' className='viewPage' />
                                                    </div>
                                                </div>
                                                <div className='custom-line'> </div>
                                                <JsonViewer data={apiData.Original_Response} />
                                            </div>
                                        </div>

                                        {showOriginal &&
                                            apiData.Attack_info.map((attack, attackIndex) => (
                                                <div key={attackIndex} className={`request-response-container-zoomIn ${showOriginal ? 'reduced-height' : ''}`}>
                                                    <div className={`request-box-zoomIn ${showOriginal ? 'reduced-height-attck' : ''}`}>
                                                        <div id='attack-header-zoomIn' style={{display:'flex',justifyContent:'space-between'}}>
                                                            <div>Attack Request</div>
                                                            <div onClick={()=>sendData(attack.Attack_request,attack.Attack_response)}><EditIcon sx={{width:'20px',height:'15px',cursor:'pointer',marginTop:'-2px'}}/></div>
                                                        </div>
                                                        <div className='custom-line'> </div>
                                                        <JsonViewer data={attack.Attack_request} />
                                                    </div>
                                                    <div className='custom-line-vertical1'> </div>
                                                    <div className={`response-box-zoomIn ${showOriginal ? 'reduced-height-attck' : ''}`}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div id='attack-header-zoomIn'>Attack Response</div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => viewHtml(attack.Attack_response.body)}>
                                                            <div className='htmlView'>HTML View</div>
                                                            <img src={viewhatmlPage} alt='view page' className='viewPage' />
                                                        </div>
                                                       </div>
                                                        <div className='custom-line'> </div>
                                                        <JsonViewer data={attack.Attack_response} />
                                                    </div>
                                                </div>
                                            ))}
                                        {/* </div> */}
                                    </div>
                                );
                            })}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ZoomInReport;
