import React from 'react'
import { useNavigate } from 'react-router-dom'
import  'Styles/DeveloperMode/PopUpUtils/Notfound.css'
const Notfoundpage = () => {
    const navigate = useNavigate();
    return (
        <div className='error-body'>
        <div className='error-container'>
            <h1 > 404 </h1>
            <p>
                Oops! The page you're
                looking for is not here.
            </p>
            <button onClick={()=>navigate('/dashboard')}>Go Back to Home</button>
            </div>
        </div>
    )
}

export default Notfoundpage