import React, { useEffect, useState } from 'react'
import 'Styles/DeveloperMode/AppDashboard/FlowsContainer.css';
import LeftSidebar from '../LeftSidebar/LeftSidebar';
import 'Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import Navbar from 'Components/DeveloperMode/MainContent/Navbar';
import AllFlowsContainer from 'Components/DeveloperMode/FlowContainer/AllFlowsContainer';
import MinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/MinimizeLeftBar';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';
import {useNavigate } from 'react-router-dom';

const FlowsContainer = ({ onLogout, onRefreshToken, minimize, setMinimize }) => {
    const [errorFlag,setErrorFlag]=useState(false);
    const navigate = useNavigate();
    const {showSnackbar}=useSnackbarContext();
    
    useEffect(() => {
      if (errorFlag) {
        navigate("/");
        showSnackbar(`The server is currently down for maintenance.`, {
          variant: 'error',
          className: 'snackbar-error',
          autoHideDuration: 2500,
        });
    
        // Reset the error flag 
        setErrorFlag(false);
      }
    }, [errorFlag, navigate, showSnackbar]);
    return (

        <div className='appDashboard'>
            <div className='sidebar'>
                {minimize ? <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}
            </div>
            <div className='content'>
                <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                    <Navbar minimize={minimize}/>
                </div>
                    <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
                        <AllFlowsContainer setErrorFlag={setErrorFlag} onRefreshToken={onRefreshToken} />
                    </div>
            </div>
        </div>

    )
}

export default FlowsContainer
