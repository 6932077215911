// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-html-container {
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;;
    height: 100%;
    background-color: rgba(26, 26, 26, 0.8);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.close-btn {
    color: #101010;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: -8px;
    align-self: flex-end;
    z-index: 3000;
}



.html-view {
    background-color: #fff;
    color: #000;
    position: relative;
    top: -4%;
    width: 93%;
    max-height: 95%;
    overflow-y: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    /* y: index 400px; */
    /* z-index: 10000; */
}


.html-view::-webkit-scrollbar{
    width: 5px;
}
.html-view::-webkit-scrollbar-track{
   background-color: #fff;
   border-radius: 10px;
}

.html-view::-webkit-scrollbar-thumb{
    background-color: #969494;
    border-radius: 10px;
 }
`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/ReportGeneration/ViewHtml.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,OAAO;IACP,WAAW;IACX,YAAY;IACZ,uCAAuC;IACvC,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,oBAAoB;IACpB,aAAa;AACjB;;;;AAIA;IACI,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,oBAAoB;IACpB,oBAAoB;AACxB;;;AAGA;IACI,UAAU;AACd;AACA;GACG,sBAAsB;GACtB,mBAAmB;AACtB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;CACtB","sourcesContent":[".view-html-container {\n    position: fixed;\n    top: 0%;\n    left: 0;\n    width: 100%;;\n    height: 100%;\n    background-color: rgba(26, 26, 26, 0.8);\n    color: #fff;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    z-index: 2000;\n}\n\n.close-btn {\n    color: #101010;\n    border: none;\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    margin-bottom: 20px;\n    margin-top: -8px;\n    align-self: flex-end;\n    z-index: 3000;\n}\n\n\n\n.html-view {\n    background-color: #fff;\n    color: #000;\n    position: relative;\n    top: -4%;\n    width: 93%;\n    max-height: 95%;\n    overflow-y: auto;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);\n    /* y: index 400px; */\n    /* z-index: 10000; */\n}\n\n\n.html-view::-webkit-scrollbar{\n    width: 5px;\n}\n.html-view::-webkit-scrollbar-track{\n   background-color: #fff;\n   border-radius: 10px;\n}\n\n.html-view::-webkit-scrollbar-thumb{\n    background-color: #969494;\n    border-radius: 10px;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
