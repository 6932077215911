import React, { useEffect, useState } from 'react';
import 'Styles/DeveloperMode/Navbar/ReportNavbar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import search from 'Images/searchReport.svg'
import { useWorkflowDetails } from 'Components/StateManagement/WorkflowDetailsContext';
import CustomDropdown from 'Components/DeveloperMode/ReportContainer/CustomDropdown';


const ReportNavbar = ({setFilteredData, minimize,setMaxFilteredData,report_data,setVulType }) => {

    const { flowName, setFlowName } = useWorkflowDetails('');
    const { runName, setRunName } = useWorkflowDetails('');
    const { appname, setAppname } = useWorkflowDetails('');
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const segments = path.split('/');
    const vulOptions = ['All', 'more_analysis', 'false_positive', 'confirm_vulnerability'];
    const [selectedVulType, setSelectedVulType] = useState('Vulnerability');
    // const [filteredData, setFilteredData] = useState([]);
    const {combineCWE}=useWorkflowDetails();

    //capitalize the first letter 
    const capitalizeFirstLetter = (text) => {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    // Decode URL segments
    const displayText1 = segments[1] ? decodeURIComponent(segments[1]) : '';
    const displayText2 = segments[2] ? decodeURIComponent(segments[2]) : '';
    const displayText3 = segments[3] ? decodeURIComponent(segments[3]) : '';
    const displayText4 = segments[4] ? decodeURIComponent(segments[4]) : '';

    useEffect(() => {
        const storedApp = localStorage.getItem('appname');
        const storedFlow = localStorage.getItem('flowName');
        const storedRun = localStorage.getItem('runName');

        if (storedApp) {
            setAppname(storedApp);
        }
        if (storedFlow) {
            setFlowName(storedFlow);
        }
        if (storedRun) {
            setRunName(storedRun);
        }
    }, [setAppname, setFlowName, setRunName]);

    const handleRoute = (text) => {
        let newPath = '/';
        if (path.startsWith('/')) {
            if (text === displayText1) {
                newPath += 'dashboard';
            } else if (text === appname) {
                newPath += `${displayText1}/${appname}`;
            } else if (text === flowName) {
                newPath += `${displayText1}/${appname}/${flowName}`;
            } else if (text === runName) {
                newPath += `${displayText1}/${appname}/${flowName}/${runName}`;
            }
        }
        navigate(newPath);
    };
    const handleVulTypeSelection = (event) => {
        console.log(event)
        setSelectedVulType(event);
        filterByVulType(event);
        setVulType(event);
    };
    const filterByVulType = (vulType) => {
        let filtered;

        if (vulType === 'All') {
            // If 'All' is selected, return the entire report data
            filtered = report_data;
        } else {
            filtered = report_data.map(item => {
                // Filter Attack_info based on the selected attack_analysis (vulType)
                const filteredAttackInfo = item.report_data.map(reportItem => {
                    const filteredAttacks = reportItem.Attack_info.filter(attack =>
                        attack.analysis_bucket === vulType
                        

                    );
                    // If there are matching attacks, return a new report_item with filtered Attack_info
                    if (filteredAttacks.length > 0) {
                        return {
                            ...reportItem,  // Spread the original reportItem
                            Attack_info: filteredAttacks  // Replace Attack_info with the filtered data
                        };
                    }
                    return null; // If no matching Attack_info, return null
                }).filter(reportItem => reportItem !== null); // Filter out null entries

                // If there are matching report items, return the updated item
                if (filteredAttackInfo.length > 0) {
                    return {
                        ...item,  // destructure the object using spread operator
                        report_data: filteredAttackInfo  // Replace report_data with the filtered report items
                    };
                }
                return null; // If no match, return null
            })
                .filter(item => item !== null); // Filter out null entries
        }
        // console.log(filtered.length,"hello")

        setFilteredData(filtered);
        setMaxFilteredData(filtered);
    };
    return (
        <div className={`ReportNavbar-content ${minimize ? 'minimized' : ''}`}>
            <div className='route-content'>
                <span id="d1" onClick={() => handleRoute(displayText1)}>{capitalizeFirstLetter(displayText1.toUpperCase())}</span>
                {/* Check if the displayText1 matches the appname */}
                {displayText2 === appname && (
                    <div style={{ display: 'flex' }}>
                        <pre style={{ paddingBottom: '11px' }}> / </pre>
                        <span id="d2" onClick={() => handleRoute(appname)} style={{ paddingTop: "11px" }}>{appname}</span>
                    </div>
                )}

                {/* Check if the displayText2 matches the flowName */}
                {displayText3 === flowName && (
                    <div style={{ display: 'flex' }}>
                        <pre style={{ paddingBottom: '11px' }}> / </pre>
                        <span id="d2" onClick={() => handleRoute(flowName)} style={{ paddingTop: "11px" }}>{flowName}</span>
                    </div>
                )}

                {/* Check if the displayText3 matches the runName */}
                {displayText4 === runName && (
                    <div style={{ display: 'flex' }}>
                        <pre style={{ paddingBottom: '11px' }}> / </pre>
                        <span id="d2" onClick={() => handleRoute(runName)} style={{ paddingTop: "11px" }}>{runName}</span>
                    </div>
                )}

                {
                    combineCWE && (
                        <div style={{ display: 'flex' }}>
                            <pre style={{ paddingBottom: '11px' }}> / </pre>
                            <span id="d2" style={{ paddingTop: "11px" }}>{combineCWE}</span>
                        </div>
                    )}
            </div>

            <div className='filterReport'>
                {/* <SearchBar />

                <img className='iconImg' style={{ cursor: 'pointer' }} src={sunIconLight} alt='sun' />

                <img className='iconImg' style={{ cursor: 'pointer' }} src={lightTimer} alt='timer' />

                <img className='iconImg' style={{ cursor: 'pointer' }} src={lightMenu} alt='menuitem' /> */}
                <img src={search} alt='search' style={{cursor:'pointer'}} />
                <div className='custom-Dropdown'>
                    <CustomDropdown 
                     options={vulOptions}
                     selectedOption={selectedVulType}
                     onSelect={handleVulTypeSelection}
                    />
                </div>
                <button className='retest' disabled={true}>Re-test</button>
            </div>
        </div>
    );
}

export default ReportNavbar;


