// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appDashboard {
    display: grid;
    grid-template-columns: 280px auto;
    grid-template-rows: 1fr;

    margin-top: 0px;
}

.sidebar {
    border-radius: 10px;
    margin-top: -25px;
}

.content {
    display: flex;
    flex-direction: column;
}

.navbar-container {
    margin-top: 10px;
    height: 50px;
    border-radius: 10px;
    margin-right: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.mainContent {
    margin-top: -10px;
    border-radius: 10px;
}

.navbar-container.minimized {
    margin-left: -170px;
    width: calc(100% - 72px);
    width: 1345px;

}

.mainContent.minimized {
    margin-left: -170px;
    width: calc(100% - 72px);
    width: 1365px;

}

@media(min-width: 1500.5px) {
    .navbar-container.minimized {
        margin-left: -170px;
        width: calc(100% - 72px);
        width: auto;

    }

    .mainContent.minimized {
        margin-left: -170px;
        width: calc(100% - 72px);
        width: auto;

    }
}`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/AppDashboard/AppDashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iCAAiC;IACjC,uBAAuB;;IAEvB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,wBAAwB;IACxB,aAAa;;AAEjB;;AAEA;IACI,mBAAmB;IACnB,wBAAwB;IACxB,aAAa;;AAEjB;;AAEA;IACI;QACI,mBAAmB;QACnB,wBAAwB;QACxB,WAAW;;IAEf;;IAEA;QACI,mBAAmB;QACnB,wBAAwB;QACxB,WAAW;;IAEf;AACJ","sourcesContent":[".appDashboard {\n    display: grid;\n    grid-template-columns: 280px auto;\n    grid-template-rows: 1fr;\n\n    margin-top: 0px;\n}\n\n.sidebar {\n    border-radius: 10px;\n    margin-top: -25px;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n}\n\n.navbar-container {\n    margin-top: 10px;\n    height: 50px;\n    border-radius: 10px;\n    margin-right: 20px;\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\n}\n\n.mainContent {\n    margin-top: -10px;\n    border-radius: 10px;\n}\n\n.navbar-container.minimized {\n    margin-left: -170px;\n    width: calc(100% - 72px);\n    width: 1345px;\n\n}\n\n.mainContent.minimized {\n    margin-left: -170px;\n    width: calc(100% - 72px);\n    width: 1365px;\n\n}\n\n@media(min-width: 1500.5px) {\n    .navbar-container.minimized {\n        margin-left: -170px;\n        width: calc(100% - 72px);\n        width: auto;\n\n    }\n\n    .mainContent.minimized {\n        margin-left: -170px;\n        width: calc(100% - 72px);\n        width: auto;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
