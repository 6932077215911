import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'Styles/DeveloperMode/AppDashboard/LeftSidebar.css';
import logo from 'Images/Group 1000004408.svg';
import vector from 'Images/Vector1.svg';
import icon1 from 'Images/data.svg';
import icon2 from 'Images/note.svg';
import icon3 from 'Images/maximize-2.svg';
import icon4 from 'Images/align-left.svg';
import icon5 from 'Images/Icon (from Tabler.io) (4).svg';
import icon6 from 'Images/logout.svg';
import User from 'Components/UserAuthentication/User';
import { useWorkflowDetails } from 'Components/StateManagement/WorkflowDetailsContext';
import Cookies from 'js-cookie';
// minimize={minimize} setMinimize={setMinimize}
const ApplicationLeftBar = ({ onLogout,minimize,setMinimize }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { appname, setAppname } = useWorkflowDetails();

  useEffect(() => {
    const storeapp = localStorage.getItem('appname');
    if (storeapp) {
      setAppname(storeapp);
    }
  }, [setAppname]);
  useEffect(() => {

    const pathname = location.pathname;


    let activeItem = 'Overview';
    // if (pathname.includes(`/application/${appname}`)) {
    //   activeItem = 'Overview';
    // } else if (pathname.includes(`/${appname}/flows`)) {
    //   activeItem = 'Flows';
    // } else if (pathname.includes(`/${appname}/report`)) {
    //   activeItem = 'Reports';
    // } else if (pathname.includes(`/${appname}/activity-planner`)) {
    //   activeItem = 'Run Activity & Planner';
    // } else if (pathname.includes(`/${appname}/threat-matrix`)) {
    //   activeItem = 'Threat Matrix';
    // } else if (pathname.includes(`/${appname}/settings`)) {
    //   activeItem = 'Settings';
    // }

    // Set the active item class based on the updated active item
    const menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach(item => {
      if (item.textContent.trim() === activeItem) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  }, [location.pathname]);

  const handleItemClick = (item) => {
    switch (item) {
      case 'Overview':
        // navigate(`/application/${appname}`);
        navigate(`/dashboard`)
        break;
 
      // break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    Cookies.remove('roles');
    Cookies.remove('firstName');
    // Cookies.remove('lastName');
    localStorage.removeItem('accessToken');
    onLogout();
    navigate('/');
    // window.location.href = '/';
  };
  const handleMaximize =()=>{
    setMinimize(false);
  }
  return (
    <div className='menu'>
      <div className='menu-form'>
        <img className='WasLogo' src={logo} alt='logo' onClick={handleMaximize}/>
        <div className='All-item'>
          <div>
            <div className="menu-item" onClick={() => handleItemClick('Overview')}>
              <img className='WasImg' src={vector} alt='vector' />
              Overview
            </div>
          </div>

          <div>
            <div className="menu-item" onClick={() => handleItemClick('Flows')}>
              <img className='WasImg' src={icon1} alt='icon1' />
              Workflows
            </div>
          </div>

          <div className="menu-item" onClick={() => handleItemClick('Reports')}>
            <img className='WasImg' src={icon2} alt='icon2' />
            Reports
          </div>

          <div className="menu-item" onClick={() => handleItemClick('Run Activity & Planner')}>
            <img className='WasImg' src={icon3} alt='icon3' />
            Run Activity & Planner
          </div>

          <div className="menu-item" onClick={() => handleItemClick('Threat Matrix')}>
            <img className='WasImg' src={icon4} alt='icon3' />
            Threat Matrix
          </div>
        </div>

        <div className='second-menu'>
          <div className='user-role1'>
            {/* <Stack> */}
            <User minimize={minimize}  />
            {/* </Stack> */}
          </div>
          <div className='user-role'>
            <div className="menu-item" onClick={() => handleItemClick('Settings')}>
              <img className='WasImg' src={icon5} alt='icon3' />
              Settings
            </div>

            <div className='menu-item' onClick={handleLogout} style={{ color: '#EB5050' }}>
              <img className='WasImg' src={icon6} alt='icon5' />
              Log Out
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationLeftBar;
