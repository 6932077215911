import React from 'react';
import 'Styles/DeveloperMode/ReportGeneration/ViewHtml.css'
import CloseIcon from '@mui/icons-material/Close';
import create from 'react-shadow';

const ViewHtml = ({ htmlContent, setViewHtml }) => {

    return (
        <div className={`view-html-container `}>
            {/* <div className='view-html'> */}
            <button onClick={() => setViewHtml(false)} className={`close-btn`}><CloseIcon sx={{ color: '#fff', stroke: '#fff', strokeWidth: 0.5, fontSize: '1.5rem' }} />
            </button>
            <create.div className={`html-view `}>
                <div
                    
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
            </create.div>

            {/* </div> */}
        </div>
    );
};

export default ViewHtml;
