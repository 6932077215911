
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'Styles/UserAuthentication/Login.css';
import logo from 'Images/Group 1000004408.svg';
import eye from 'Images/eye.svg';
import googleLogo from 'Images/googleLogo.svg';
import '@fontsource/dm-sans';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';

const Login = ({ onLogin, googlelogin }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const apiUrl = process.env.REACT_APP_URL;
  const { showSnackbar } = useSnackbarContext();
  const [warningFlag, setWarningFlag] = useState(false);
  const [isClickedButton,setIsClickedButton]=useState(false);
  const [loginAttempts,setLoginAttempts]=useState(0);
  const [isUserBlocked,isSetUserBlocked]=useState(false);

  //==================form submit===========================

  const location = useLocation();

  const [signupSuccess] = useState(location.state?.signupSuccess);

  // show snackbar for incorret credentials
  useEffect(()=>{
    if(warningFlag){
      showSnackbar(`Incorrect Username or Password.`, { 
        variant: 'warning',  
        className: 'snackbar-warning', 
        autoHideDuration: 1000,
       });

      // reset the warning flag
      setWarningFlag(false);
    }
  },[warningFlag,showSnackbar]);

  // signup success message show after successful creating user
  useEffect(() => {
    // Clear the navigation state after displaying the success message
    if (signupSuccess) {
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [signupSuccess, navigate, location.pathname]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isUserBlocked) {
      // Show the "temporarily blocked" snackbar if the user is already blocked
      showSnackbar(
        `You are temporarily blocked due to multiple failed attempts. Please try again later.`,
        {
          variant: 'error',
          className: 'snackbar-error',
          autoHideDuration: 5000,
        }
      );
      return; 
    }
    const loginStatus = await onLogin(username, password);
    if (loginStatus === 'success') {
      navigate(`/dashboard`)
      setWarningFlag(false);


    } else {
      // setWarningFlag(true);
      setLoginAttempts((prev)=>prev+1);
      if (!isUserBlocked) {
        setWarningFlag(true);
      }

    }


  };


  useEffect(()=>{
    if(loginAttempts >3){
      isSetUserBlocked(true);
      showSnackbar(`You are temporarily blocked due to multiple failed attempts. Please try again later.`,{
        variant:'error',
        className:'snackbar-error',
        autoHideDuration:5000,
      });

      const timer=setTimeout(()=>{
        isSetUserBlocked(false);
        setLoginAttempts(0);
      },20000); //block duration 20sec
      return ()=>clearTimeout(timer);

    }
  },[loginAttempts,showSnackbar]);

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    window.location.href = `${apiUrl}/auth/login/google`
    // try {
    //   const loginStatus = await googlelogin();
    //   console.log('Login status:', loginStatus);
    // } catch (error) {
    //   console.error('Error during Google login:', error);
    // }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };


  const handleClickButton =()=>{
    setIsClickedButton(true);

    //reset click animation after 200ms
    setTimeout(() => {
      setIsClickedButton(false);
    }, 200);
  }


  return (
    <div className='background'>
      <div className='login'>
        <img src={logo} alt='logo' />
        <div className='loginForm'>
          {signupSuccess && <p style={{ color: '#000', backgroundColor: '#d1e6f9', borderRadius: '8px', padding: '5px' }}>Signup successful! Please log in.</p>}
          <form onSubmit={handleSubmit}>

            <p id='login-txt' style={{ marginTop: "20px" }} >Log In</p>
            <div className='login-details'>
              <label htmlFor='email'>Your email </label>
              <input
                type='text'
                id='email'
                name='email'
                placeholder='name@example.com'
                value={username}
                onChange={(e) => { setUsername(e.target.value) }}
                required />
            </div>

            <div className='login-details'>
              <label htmlFor='pass'>Password  </label>
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id='pass'
                  name='pass'
                  placeholder='******'
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                  required
                />
                <img
                  src={eye}
                  alt='Show/Hide Password'
                  style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', paddingTop: "50px" }}
                  onClick={toggleShowPassword}
                  className='eye1'
                />
              </div>
            </div>


            <p id='forgetPassword'>Forgot password?</p>
            <div className={`wrap ${isClickedButton ? "clicked": ""}`} style={{ marginTop: "20px" }}>
              <button type='submit' className={`btn ${isClickedButton ? "clicked": ""}`} onClick={handleClickButton} >Login</button>
            </div>
          </form>
          <div>
            <p style={{ height: "20px", marginTop: "-30px" }}>or</p>
            <button style={{ border: "none", backgroundColor: "#fff", cursor: "pointer" }} onClick={handleGoogleLogin}>
              <img src={googleLogo} alt='goolelogo' style={{ height: "30px", marginBottom: "-60px" }} />
            </button>
          </div>
          <p>Don't have an account ? <Link to='/signup' style={{ color: "black", fontWeight: "bold" }}> Sign Up</Link> </p>
        </div>
      </div>

    </div>
  )
}

export default Login;
