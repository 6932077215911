
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import '@fontsource/dm-sans'
import 'Styles/DeveloperMode/Graph_content/Top10CWE.css'

const Top10CWE = ({ ottInfoMap, theme }) => {
    const [chartData, setChartData] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                type: 'bar',
                // height: 220,
                toolbar: {
                    show: false
                  },
            },
            tooltip: {
                enabled: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                    distributed: true,
                    columnWidth:'25%',
                    // endingShape:'rounded'

                }
            },
            title: {
                align: 'left',
                
                style: {
                    color: '#868585'
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
                // customLegendItems: ['In house API', '3rd party API'],
                // markers: {
                //     fillColors: ['#FFCB2D', '#FF7917']
                //   }
            },
            xaxis: {
                categories: [],
                labels: {
                    show: true,
                    style: {
                        colors: '#868585'
                    }
                },
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true
                },

            },
            yaxis: {
                labels: {
                    show: true
                },
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true
                },
            },
            grid: {
                row: {
                  
                    opacity: 0,
                },
                column: {
                    
                    opacity: 0
                },
                padding: {
                    top: 0,
                    bottom: 0
                }
            },
           
            colors:['#FFCB2D']
        }
    });

    useEffect(() => {
        const ottKeys = Array.from(ottInfoMap.keys());
        const ottCounts = Array.from(ottInfoMap.values());

        setChartData({
            series: [{
                name: "Threats",
                data: ottCounts
            }],
            options: {
                ...chartData.options,
                xaxis: {
                    ...chartData.options.xaxis,
                    categories: ottKeys
                }
            }
        });
    }, [ottInfoMap]);

    return (
        <div >

            <div className={`top10`}> CWE Metric</div>
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={210} />

        </div>
    );
};

export default Top10CWE;
