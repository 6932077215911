import React, { useState, useEffect } from 'react';
import 'Styles/DeveloperMode/ReportGeneration/CustomDrpDown.css'
import filter from 'Images/blackSetting.svg';
const CustomDropdown = ({ options, selectedOption, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        onSelect(option);
        setIsOpen(false);
    };

   
    const convertedString = (option) => {
        const convertedOption = option.replace('_', ' ').toUpperCase();
        return convertedOption.length > 15 ? convertedOption.substring(0, 14) + '...' : convertedOption;

    }
    return (
        <div className="custom-dropdown">
            <div className="dropdown-toggle" onClick={() => setIsOpen(!isOpen)} style={{height:'20px'}} >
                <div style={{padding:'3px 0px 0px 0px',boxSizing:'border-box'}}>
                    {selectedOption ? convertedString(selectedOption) : 'Select Vulnerability Type'}
                </div>
                
                <img src={filter} alt='down' className='filterVul' style={{padding:'8px 0px 8px 0px',height:'30px',boxSizing:'border-box'}}/>

            </div>
            {isOpen && (
                <div className="dropdown-menu">
                    {options.map((option) => (
                        <div key={option} className="dropdown-item"  onClick={() => handleOptionClick(option)} >
                            {/* <label>
                                <input
                                    type="checkbox"
                                    checked={selectedOption === option}
                                    style={{cursor:'pointer'}}
                                />
                            </label> */}
                            <div className='optVul' > {option.replace('_', ' ').toUpperCase()}</div>
                        </div>
                    ))}
                  
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
