import React, { useState, useEffect } from 'react';
import 'Styles/DeveloperMode/ReportGeneration/CWEReport.css';
import ReportChart from 'Components/DeveloperMode/ReportContainer/ReportChart';
import CustomDropdown from 'Components/DeveloperMode/ReportContainer/CustomDropdown';
import { useNavigate } from 'react-router-dom';
import JsonViewer from 'Components/DeveloperMode/ReportContainer/JsonViewer';
import dataNotFound from 'Images/notDatafound.svg';
import zoomIn from 'Images/zoomIn.svg';
import exportPDF from 'Images/export.svg';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';
import viewhatmlPage from 'Images/upcollapse.svg';
import EditIcon from '@mui/icons-material/Edit';

const ShowReport = ({setIsCheckRequest,setErrorFlag,setSendAttackResponse,setSendAttackRequest,setEditAttack, setViewHtml, setHtmlbody, setZoomFlag, onRefreshToken, minimize, cweName, application, flow, run, setMaxFilteredData, setSelectApiFromReport }) => {
    // States to store different parts of the report data
    const [reportData, setReportData] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedVulType, setSelectedVulType] = useState('Vulnerability');
    const vulOptions = ['All', 'more_analysis', 'false_positive', 'confirm_vulnerability'];
    const navigate = useNavigate();
    const [showOriginalFor, setShowOriginalFor] = useState({});
    const [CWENameReport, setCWENameReport] = useState([]);
    const [accessTokenUpdated, setAccessTokenUpdated] = useState('');
    const apiUrl = process.env.REACT_APP_URL;
    const [selectedCweReport, setSelectedCweReport] = useState('');
    const [selectedApi, setSelectedApi] = useState(0);
    const [statusCodeCounts, setStatusCodeCounts] = useState({});
    // console.log("filteredData", filteredData);
    const { showSnackbar } = useSnackbarContext();
    

    useEffect(() => {
        if (application && flow && run && cweName) {
            fetchCWEList();
        }
    }, [application, flow, run, cweName]);
    useEffect(() => {
        if (accessTokenUpdated) {
            fetchCWEList();
        }
    }, [accessTokenUpdated]);
    // console.log("details required for apicall", { app_id, flowId, runId, cweName })
    const fetchCWEList = async () => {
        if (!run || !application || !flow) return;
        const localToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${apiUrl}/reports/apps/${application}/flows/${flow}/runs/${run}/cwe/${cweName}`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                // console.log(JSON.stringify(data),"hello")
                setCWENameReport(data);
            } else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            } else if (response.status === 400) {
                setFetchError(true);
                showSnackbar(`Bad Request`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })
                console.error('Bad Request:', response.statusText);
            }
            else if (response.status === 404) {
                showSnackbar(`Page Not Found`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })

            }
            else if (response.status === 500) {

                showSnackbar(`Internal Server Error`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })

            }

        } catch (error) {
            console.log("Failed to fetch reports", error);
            if (error.message.includes("ERR_CONNECTION_REFUSED") || error.message.includes("Failed to fetch")) {
                setErrorFlag(true);
            }
            setFetchError(true);
        }
    };

    const handleCweReportSelection = (event) => {
        const selectedKey = event.target.value;
        // Locate the correct object in CWENameReport array
        const cweReport = CWENameReport.find(report => report.report_info[cweName]);

        if (cweReport) {
            // Find the selected report by report_id
            const selectedReport = cweReport.report_info[cweName].find(
                (report) => report.report_id === selectedKey
            );
            if (selectedReport) {
                setSelectedCweReport(selectedKey);
                // console.log(selectedKey)
                setReportData([selectedReport] || []);
                setFilteredData([selectedReport] || []);
                setMaxFilteredData([selectedReport] || []);
                setSelectedVulType('Vulnerability');

            }

        } else {
            console.error(`No report_info found for cweName: ${cweName}`);
        }
    };

    //Automatically fetch the first report 
    useEffect(() => {
        if (CWENameReport.length > 0) {
            const cweReport = CWENameReport.find((report) => report.report_info?.[cweName]); //check  wheather it is present or not  //optional chaining-->deeply nested properties checked
            if (cweReport && cweReport.report_info[cweName]?.length > 0) {
                const firstReport = cweReport.report_info[cweName][0];
                setSelectedCweReport(firstReport.report_id);
                setReportData([firstReport] || []);
                setFilteredData([firstReport] || []);
                setMaxFilteredData([firstReport] || []);
            }
        }

    }, [CWENameReport, cweName])

    useEffect(() => {
        if (filteredData) {
            calculateStatusCodeCounts(filteredData, selectedVulType);
        }
    }, [filteredData, selectedVulType]);

    const calculateStatusCodeCounts = (data, vulType) => {
        // console.log(JSON.stringify(data), "data")
        const counts = {};
        data.forEach((report) => {
            report.report_data.forEach((item) => {
                //count for original response code
                if (vulType === 'All' || vulType === 'Vulnerability' && item.Original_Response && item.Original_Response.status_code) {
                    counts[item.Original_Response.status_code] = (counts[item.Original_Response.status_code] || 0) + 1;
                }

                //count for attack response code
                if (item.Attack_info && item.Attack_info.length > 0) {
                    item.Attack_info.forEach((attack) => {
                        if (attack.Attack_response && attack.Attack_response.status_code) {
                            counts[attack.Attack_response.status_code] = (counts[attack.Attack_response.status_code] || 0) + 1;
                        }
                    });
                }
            });
        });
        //update the state of status code
        setStatusCodeCounts(counts);
    };
    //for custom dropdown
    const handleVulTypeSelection = (event) => {
        // console.log(event)
        setSelectedVulType(event);
        filterByVulType(event);
    };
    const filterByVulType = (vulType) => {
        let filtered;

        if (vulType === 'All') {
            // If 'All' is selected, return the entire report data
            filtered = reportData;
        } else {
            filtered = reportData.map(item => {
                // Filter Attack_info based on the selected attack_analysis (vulType)
                const filteredAttackInfo = item.report_data.map(reportItem => {
                    const filteredAttacks = reportItem.Attack_info.filter(attack =>
                        attack.analysis_bucket === vulType
                    );

                    // If there are matching attacks, return a new report_item with filtered Attack_info
                    if (filteredAttacks.length > 0) {
                        return {
                            ...reportItem,  // Spread the original reportItem
                            Attack_info: filteredAttacks  // Replace Attack_info with the filtered data
                        };
                    }
                    return null; // If no matching Attack_info, return null
                }).filter(reportItem => reportItem !== null); // Filter out null entries

                // If there are matching report items, return the updated item
                if (filteredAttackInfo.length > 0) {
                    return {
                        ...item,  // destructure the object using spread operator
                        report_data: filteredAttackInfo  // Replace report_data with the filtered report items
                    };
                }
                return null; // If no match, return null
            })
                .filter(item => item !== null); // Filter out null entries
        }

        setFilteredData(filtered);
        setMaxFilteredData(filtered);

    };

    const toggleShowOriginals = (apiIndex) => {
        setShowOriginalFor((prevState) => ({
            ...prevState,
            [apiIndex]: !prevState[apiIndex],
        }));
    };
    const handleApiClick = (apiData, apiIndex) => {
        // console.log(apiIndex)
        setSelectedApi(apiIndex);
        setSelectApiFromReport(apiIndex)
    };
    const handleZoom = () => {
        setZoomFlag(true);
    }
    const convertIST = (timestamp, updatedTime) => {
        if (!timestamp) return null;
        const date = new Date(timestamp);

        return date.toLocaleString('en', {
            // timeZone: 'Asia/Kolkata',
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });
    }

    // date time in utc format 
    const convertUpdatedIST = (updatedTime) => {
        if (!updatedTime) return null;
        const now = new Date();
        const date = new Date(updatedTime);
        const diff_Time = Math.floor((now - date) / 1000); //diff in seconds
        if (diff_Time < 60) {
            return 'just now';
        } else if (diff_Time < 3600) {
            const min = Math.floor(diff_Time / 60);
            return min === 1 ? '1 min ago' : `${min} mins ago`;
        } else if (diff_Time < 86400) {
            const hour = Math.floor(diff_Time / 3600);
            return hour === 1 ? '1 hour ago' : `${hour} hours ago`;
        } else if (diff_Time < 604800) {
            const days = Math.floor(diff_Time / 86400);
            return days === 1 ? '1 day ago' : `${days} days ago`;
        } else {
            const week = Math.floor(diff_Time / 604800);
            return week === 1 ? '1 week ago' : `${week} weeks ago`
        }

    }


    const downloadPDF = (data) => {
        const doc = new jsPDF();

        data.forEach((report, idx) => {

            // Add report metadata
            doc.setFontSize(14);
            doc.setFont("helvetica", "bold");
            doc.text(`${report.cwe_info}`, 10, 10);
            doc.setFontSize(12);
            doc.setFont("helvetica", "normal");
            doc.text(`Created: ${convertIST(report.creation_time)}`, 10, 20);
            doc.text(`Updated: ${convertIST(report.last_updated_time)}`, 10, 30);

            // Prepare rows for the table
            const tableRows = [];
            const tableHeaders = ["Key", "Value"]; // Two-column layout

            report.report_data.forEach((reportData) => {
                // Original Request and Response
                tableRows.push([
                    "Original Request",
                    JSON.stringify(reportData.Original_Request, null, 2),
                ]);
                tableRows.push([
                    "Original Response",
                    JSON.stringify(reportData.Original_Response, null, 2),
                ]);

                reportData.Attack_info.forEach((attackInfo, attackIdx) => {
                    // Add Analysis Bucket above Attack Requests and Responses
                    tableRows.push([
                        `Analysis Bucket (${attackIdx + 1})`,
                        attackInfo.analysis_bucket,
                    ]);

                    // Attack Request and Response
                    tableRows.push([
                        "Attack Request",
                        JSON.stringify(attackInfo.Attack_request, null, 2),
                    ]);
                    tableRows.push([
                        "Attack Response",
                        JSON.stringify(attackInfo.Attack_response, null, 2),
                    ]);
                });
            });

            // Add the table using autotable
            doc.autoTable({
                startY: 40, // Start below the metadata
                head: [tableHeaders],
                body: tableRows,
                styles: { cellPadding: 2, fontSize: 10 },
                theme: "grid",
                columnStyles: {
                    0: { cellWidth: 40 }, // Key column
                    1: { cellWidth: 145 }, // Value column
                },
            });

            // Add a new page for the next report if there is more data
            if (idx < data.length - 1) {
                doc.addPage();
            }
        });

        // Save the PDF
        doc.save(`Report_Data.pdf`);
    };

    const viewHtml = (body) => {
        // console.log(body)
        setHtmlbody(body)
        setViewHtml(true);
    }

    const sendData=(attackDataRequest,attackDataResponse,flag)=>{
        if(flag){
         setIsCheckRequest(true);
        }else{
         setIsCheckRequest(false)
        }
        
         setEditAttack(true);
         setSendAttackRequest(attackDataRequest)
         setSendAttackResponse(attackDataResponse)
 
     }

    return (
        <div >
            {fetchError ?
                <div className={`empty-report ${minimize ? 'img' : 'none'}`}><p className='empty-msg'>Report is not generated. Please start the fuzzer or wait for a response</p></div>
                :
                (
                    reportData && (
                        <div className='report-container'>
                            <div className='report-header'>
                                <div className='select-report' style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                                    <select
                                        value={selectedCweReport}
                                        onChange={handleCweReportSelection}
                                        className="report-dropdown"
                                    >
                                        {CWENameReport && Array.isArray(CWENameReport) && CWENameReport.length > 0 ? (
                                            CWENameReport.map((item, index) => {
                                                const reports = item.report_info?.[cweName] || [];
                                                if (reports.length === 0) {
                                                    return (
                                                        <option key={`no-report-${index}`} value="" disabled>
                                                            No reports available for {cweName}
                                                        </option>
                                                    );
                                                }
                                                return reports.map((report, idx) => (
                                                    <option key={idx} value={report.report_id}>
                                                        Report  {idx + 1}
                                                        {/* (Created: {report.creation_time}) */}
                                                    </option>

                                                ));
                                            })
                                        ) : (
                                            <option value="" disabled>No reports found</option>
                                        )}
                                    </select>
                                    <div style={{ marginTop: '8px' }} >
                                        {filteredData.map((item, idx) => (
                                            <div>
                                                <div key={idx} className='date-time' style={{ display: 'flex', gap: '5px' }}>
                                                    Creation Time :{item.creation_time ? <div>{convertIST(item.creation_time)}</div> : ""}
                                                </div>
                                                <div key={idx} className='date-time' style={{ display: 'flex', gap: '5px' }}>
                                                    Updated at {item.last_updated_time ? <div>{convertUpdatedIST(item.last_updated_time)}</div> : ""}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>

                                <div className="dropdownVul" style={{ display: 'flex' }}>
                                    <CustomDropdown
                                        options={vulOptions}
                                        selectedOption={selectedVulType}
                                        onSelect={handleVulTypeSelection}
                                    />
                                    {filteredData.length === 1 ?
                                        <button onClick={() => downloadPDF(filteredData)} style={{ boxSizing: 'border-box', border: 'none', backgroundColor: '#f6f6f6' }}>
                                            <img src={exportPDF} alt='download pdf' style={{ width: '20px', marginLeft: '5px' }} />
                                        </button> : ''}
                                </div>
                            </div>
                            {filteredData.length === 1 ?
                                <section className='report-section'  >
                                    <div className='graphical-data'>
                                        <div >

                                            <div id='res-code'>Response Code</div>

                                        </div>
                                        <div id='donut-graph'><ReportChart statusCodeCounts={statusCodeCounts} /></div>

                                        <div className='show-apis'>
                                            {filteredData.map((reportItem, reportIndex) => (
                                                <div key={reportIndex} className='cwe-container'>
                                                    {reportItem.report_data.map((apiData, apiIndex) => {
                                                        const originalRequestUrl = apiData.Original_Request?.url || 'N/A';
                                                        const attackInfoCount = apiData.Attack_info?.length || 0;
                                                        return (
                                                            <div
                                                                className={`selected-api ${selectedApi === apiIndex ? 'expanded' : ''}`}
                                                                style={{ backgroundColor: selectedApi === apiIndex ? '#FFCB2D' : '#fff', border: selectedApi === apiIndex ? '1px solid #FFCB2D' : '' }}
                                                                key={apiIndex}
                                                                onClick={() => handleApiClick(apiData, apiIndex)}
                                                            >
                                                                <div className='dropdown-title'>
                                                                    <div id='api-headerName'>
                                                                        {originalRequestUrl.length > 20 ? `...${originalRequestUrl.slice(-20)}` : originalRequestUrl}
                                                                    </div>
                                                                    <div id='attack-count' style={{ marginLeft: '10px', fontSize: '14px', color: '#555' }}>
                                                                        ({attackInfoCount} {attackInfoCount === 0 ? "Attack" : "Attacks"})
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                        </div>

                                    </div>

                                    <div className='api-dropdown'>
                                        {selectedApi === null ? <div className='initial-report'><img src={dataNotFound} alt='data not found' /></div> : ""}
                                        {selectedApi !== null && filteredData.map((reportItem) => (
                                            <div key={selectedApi} className='cwe-container'>
                                                {reportItem.report_data.map((apiData, apiIndex) => {
                                                    if (apiIndex !== selectedApi) return null; // Only show details for selected API

                                                    const showOriginal = showOriginalFor[apiIndex] || false;
                                                    const originalRequestUrl = apiData.Original_Request?.url || 'N/A';

                                                    return (
                                                        <div key={apiIndex} className='cwe-attack-expanded'>

                                                            <div className='spit-data'>
                                                                <div className='dropdown-header'>
                                                                    <div id='api-headerName'>
                                                                        {originalRequestUrl.length > 75 ? `${originalRequestUrl.slice(0, 75)}...` : originalRequestUrl}
                                                                    </div>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                                        <div className='checkbox-container'>
                                                                            <input
                                                                                style={{ cursor: "pointer" }}
                                                                                type='checkbox'
                                                                                id={`checkbox-${apiIndex}`}
                                                                                checked={showOriginal}
                                                                                onChange={(e) => {
                                                                                    e.stopPropagation();
                                                                                    toggleShowOriginals(apiIndex);
                                                                                }}
                                                                            />
                                                                            <label htmlFor={`checkbox-${apiIndex}`} className='checkbox-label'>Show Attack Data</label>
                                                                        </div>
                                                                        <div style={{ marginLeft: '10px', border: '1px solid #383838', height: '25px', borderRadius: '5px' }} ><img src={zoomIn} alt='Zoom In' style={{ marginTop: '0px', width: '25px', height: '25px' }} onClick={() => { handleZoom() }} /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Original Request and Response */}
                                                            <div className='original-request-response-container'>
                                                                <div className='original-request-box'>
                                                                    <div className='attack-header' id='attack-header'  style={{display:'flex',justifyContent:'space-between'}}>
                                                                        <div>Original Request</div>
                                                                        <div onClick={()=>sendData(apiData.Original_Request,apiData.Original_Response,true)} style={{marginRight:'8px'}}><EditIcon sx={{width:'25px',height:'15px',cursor:'pointer',marginTop:'-5px'}}/></div>
                                                                        
                                                                    </div>
                                                                    <div className='custom-line'> </div>
                                                                    <JsonViewer data={apiData.Original_Request} />
                                                                </div>
                                                                <div className='original-response-box'>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <div id='attack-header'>Original Response</div>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => viewHtml(apiData.Original_Response.body)}>
                                                                            <div className='htmlView'>HTML View</div>
                                                                            <img src={viewhatmlPage} alt='view page' className='viewPage' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='custom-line'> </div>
                                                                    <JsonViewer data={apiData.Original_Response} />
                                                                </div>
                                                            </div>

                                                            {showOriginal && apiData.Attack_info.map((attack, attackIndex) => (
                                                                <div key={attackIndex} className='request-response-container'>
                                                                    <div className='request-box'>
                                                                        <div id='attack-header' style={{display:'flex',justifyContent:'space-between'}}>
                                                                            <div>Attack Request</div>
                                                                            <div onClick={()=>sendData(attack.Attack_request,attack.Attack_response)} style={{marginRight:'8px'}}><EditIcon sx={{width:'25px',height:'15px',cursor:'pointer',marginTop:'-5px'}}/></div>
                                                                        </div>
                                                                        <div className='custom-line'> </div>
                                                                        <JsonViewer data={attack.Attack_request} />
                                                                    </div>
                                                                    <div className='response-box'>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <div id='attack-header'>Attack Response</div>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => viewHtml(attack.Attack_response.body)}>
                                                                                <div className='htmlView'>HTML View</div>
                                                                                <img src={viewhatmlPage} alt='view page' className='viewPage' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='custom-line'> </div>
                                                                        <JsonViewer data={attack.Attack_response} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </div>

                                </section>
                                : (
                                    <div><img src={dataNotFound} alt='data not found' className='empty-report-page' /></div>
                                )
                            }
                        </div>
                    )
                )}
        </div>
    );
};

export default ShowReport
