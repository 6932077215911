import React, { useState } from "react";
import "Styles/DeveloperMode/ReportGeneration/EditAttack.css";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import TextareaAutosize from "@mui/material/TextareaAutosize";


const EditAttack = ({isCheckRequest, sendAttackResponse, sendAttackRequest, setEditAttack, editAttack }) => {
    const toggleModal = () => {
        setEditAttack(false);
    };
// const [responseData, setResponseData] = useState(null);
    const { url, method, headers, body } = sendAttackRequest;
    const [editableData, setEditableData] = useState({
        url: url || "",
        method: method || "",
        headers: headers || {},
        cookies: headers?.Cookie
            ? Object.entries(headers.Cookie).map(([key, value]) => ({ key, value }))
            : [],
        body: body || "",
    });

     // Handle changes for regular headers or body
    const handleInputChange = (e, field, key) => {
        if (field === "headers") {
            setEditableData((prev) => ({
                ...prev,
                headers: {
                    ...prev.headers,
                    [key]: e.target.value,
                },
            }));
        } else {
            setEditableData((prev) => ({
                ...prev,
                [field]: e.target.value,
            }));
        }
    };

     // Handle changes to individual cookie key-value pairs
    const handleCookieChange = (index, field, value) => {
        const updatedCookies = [...editableData.cookies];
        updatedCookies[index][field] = value;
        setEditableData((prev) => ({
            ...prev,
            cookies: updatedCookies,
        }));
    };

     // Save updated data
    const handleSave = async () => {
        const cleanData = { ...editableData };

        cleanData.headers.Cookie = cleanData.cookies.reduce((acc, { key, value }) => {
            if (key) acc[key] = value;
            return acc;
        }, {});

        delete cleanData.cookies;

        // console.log(JSON.stringify(cleanData.url, null, 2));
        // console.log(JSON.stringify(cleanData.headers, null, 2));
        // console.log(JSON.stringify(cleanData.body, null, 2));
        // console.log(JSON.stringify(cleanData.method, null, 2));

        console.log(JSON.stringify(cleanData, null, 2));
                // try {
        
                //     const response = await fetch(cleanData.url, {
                //         method: cleanData.method,
                //         headers: cleanData.headers,
                //         mode: 'no-cors',
                //         body: cleanData.body ? JSON.stringify(cleanData.body) : undefined,
                //     });
                //     console.log(JSON.stringify(response))

                //     if (!response.ok) {
                //         throw new Error(`Error: ${response.status} ${response.statusText}`);
                //     }
        
                //     const responseData = await response.json();
                //     console.log(JSON.stringify(responseData,null,2))
                //     setResponseData(responseData);
                // } catch (err) {
                //     setResponseData(null);
                // } finally {
                // }
    };

    return (
        <div className="edit-request">
            {editAttack && (
                <div className="edit-modal-data">
                    <div className="edit-modal-data-content">
                        <div className="input-edited-data">
                            <div>
                                { isCheckRequest ? 
                                <p style={{fontFamily:'DM Sans',fontSize:'22px',margin:'8px',color:'#313131'}}>Original Request Details</p> :
                                <p style={{fontFamily:'DM Sans',fontSize:'22px',margin:'8px',color:'#313131'}}>Attack Request Details</p>
                                }
                               
                                <div className="border-line"></div>
                            </div>
                            <Box
                                component="form"
                                sx={{ "& > :not(style)": { m: 2, width: "100%" } }}
                                noValidate
                                autoComplete="off"
                             >
                                <div>
                                    <p style={{marginBottom:'5px',fontSize:'18px'}}>URL:</p>
                                    <Input
                                        placeholder="Enter URL"
                                        value={editableData.url}
                                        onChange={(e) => handleInputChange(e, "url")}
                                        // fullWidth
                                        sx={{width:'94%',color:'#5b5a5a',fontSize:'15px'}}
                                    />
                                </div>
                                <div>
                                    <p style={{marginBottom:'5px',fontSize:'18px'}}>Method:</p>
                                    <Input
                                        placeholder="Enter HTTP Method"
                                        value={editableData.method}
                                        onChange={(e) => handleInputChange(e, "method")}
                                        sx={{width:'94%',color:'#5b5a5a',fontSize:'15px'}}
                                    />
                                </div>
                                <div>
                                    <p style={{marginBottom:'8px',fontSize:'18px'}}>Headers:</p>
                                    {Object.keys(editableData.headers || {}).map((key) =>
                                        key === "Cookie" ? null : (
                                            <div key={key} style={{ marginBottom: "8px",marginLeft:'10px' }}>
                                                <p style={{marginBottom:'5px',fontSize:'16px'}}>{key}:</p>
                                                <Input
                                                    placeholder={`Enter value for ${key}`}
                                                    value={editableData.headers[key]}
                                                    onChange={(e) => handleInputChange(e, "headers", key)}
                                                    sx={{width:'94%',color:'#5b5a5a',fontSize:'15px'}}
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                                <div>
                                    <p style={{marginBottom:'8px',fontSize:'18px'}}>Cookies:</p>
                                    {editableData.cookies.map((cookie, index) => (
                                        <div key={index} style={{ marginBottom: "8px",marginLeft:'10px' }}>
                                            <p style={{marginBottom:'5px',fontSize:'16px'}}>{cookie.key}:</p>
                                            <TextareaAutosize
                                                placeholder="Enter cookie value"
                                                value={cookie.value}
                                                onChange={(e) => handleCookieChange(index, "value", e.target.value)}
                                                rowsMin={3}
                                                style={{ width: "94%",color:'#5b5a5a',fontSize:'15px',borderRadius:'8px' }}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <p style={{marginBottom:'5px',fontSize:'18px'}}>Body:</p>
                                    <TextareaAutosize
                                        placeholder="Enter body"
                                        value={editableData.body}
                                        onChange={(e) => handleInputChange(e, "body")}
                                        rowsMin={6}
                                        style={{ width: "94%",color:'#5b5a5a',fontSize:'15px' ,borderRadius:'8px'}}
                                    />
                                </div>
                            </Box>
                            <div className="save-button">
                                <button onClick={handleSave} className="saveButton" disabled={true}>
                                    Perform Attack
                                </button>
                            </div>
                        </div>
                        <div className="edit-line"></div>
                        <div className="output-edited-data">
                            <p style={{fontFamily:'DM Sans',fontSize:'22px',margin:'8px',color:'#313131'}}>Attack Response Details</p>
                            <div className="border-line"></div>
                            <pre>{JSON.stringify(sendAttackResponse, null, 2)}</pre>
                        </div>
                    </div>
                    <span className="closeBox" onClick={toggleModal} style={{ color: "#fff" }}>
                        &times;
                    </span>
                </div>
            )}
        </div>
    );
};

export default EditAttack;
