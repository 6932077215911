import React, { useState } from 'react';
import 'Components/DeveloperMode/ReportContainer/JsonViewer.css';
import arrowUp from 'Images/upcollapse.svg';
import downArrow from 'Images/down.svg';

const JsonViewer = ({ data }) => {
    // Initialize the openKeys with `Modified_data` and its nested contents expanded
    const initializeOpenKeys = (data, parentKey = '') => {
        const keys = {};

        const recursiveSetKeys = (obj, keyPath) => {
            if (!obj || typeof obj !== 'object') return;

            Object.entries(obj).forEach(([key, value]) => {
                const fullPath = keyPath ? `${keyPath}.${key}` : key;

                // Expand `Modified_data` and all its nested contents
                if (key === 'Modified_data' || keyPath.startsWith('Modified_data')) {
                    keys[fullPath] = true;
                }

                // If the value is an object or array, recurse
                if (typeof value === 'object' && value !== null) {
                    recursiveSetKeys(value, fullPath);
                }

                // If the value is an array, expand its elements
                if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        recursiveSetKeys(item, `${fullPath}[${index}]`);
                    });
                }
            });
        };

        recursiveSetKeys(data, parentKey);
        return keys;
    };

    const [openKeys, setOpenKeys] = useState(() => initializeOpenKeys(data));

    // Toggle the state of a specific key path
    const toggleKey = (keyPath) => {
        setOpenKeys((prevState) => ({
            ...prevState,
            [keyPath]: !prevState[keyPath],
        }));
    };

    // Function to create a unique key path for each nested object
    const getKeyPath = (parentKey, childKey) => {
        return parentKey ? `${parentKey}.${childKey}` : childKey;
    };

    // Check if the data is an object and not null
    const isObject = (value) => typeof value === 'object' && value !== null;

    const highlightedObject=(obj)=>{
        if (!isObject(obj)) return false;

        const keys = ['Original key', 'Payload key', 'Original value', 'Payload value'];
        return keys.every((key) => key in obj);
    }
    
    // Recursive function to render JSON data
    const renderJson = (data, parentKey = '') => {
        return Object.entries(data).map(([key, value], index) => {
            const keyPath = getKeyPath(parentKey, key);
            const isExpanded = openKeys[keyPath];

   
            return (
                <div key={index} className={`json-entry ${highlightedObject(value)?'highlighted-object':''}`}>
                    {isObject(value) && (
                        <span onClick={() => toggleKey(keyPath)}>
                            {isExpanded ? (
                                <img src={downArrow} alt='down' className='collapse' />
                            ) : (
                                <img src={arrowUp} alt='up' className='expand' />
                            )}
                        </span>
                    )}

                    <span className={`json-key`} onClick={() => toggleKey(keyPath)}>{key}</span>

                    {isExpanded && isObject(value) ? (
                        <div className="json-object">
                            {renderJson(value, keyPath)}
                        </div>
                    ) : (
                        !isObject(value) && (
                            <span className={`json-value ${key === 'Payload key' || key === 'Original key' || key === 'attack_name' || key === 'CWE-No' || key === 'status_code'? 'highlight-value': ''} ${key === 'Original value' || key === 'Payload value'? 'attack-value': ''}`} >
                                : {JSON.stringify(value)}
                            </span>
                        )
                    )}
                </div>
            );
        });
    };

    return <div>{renderJson(data)}</div>;
};

export default JsonViewer;
