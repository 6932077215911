import React, { useState } from 'react';
import ApplicationLeftBar from 'Components/DeveloperMode/ApplicationDashboard/ApplicationLeftBar.js'; 
import ApplicationNavbar from 'Components/DeveloperMode/ApplicationDashboard/ApplicatonNavbar.js';
import 'Styles/DeveloperMode/AppDashboard/AddApplication.css';
import VerticalStepper from 'Components/CisoMode/VerticalStepper.js';
import ApplicationMinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/ApplicationMinimizeLeftBar.js';
import Notfoundpage from 'Components/DeveloperMode/FlowDB/Notfoundpage';
import appIcon from 'Images/app_add.svg'

const AddApplication = ({ onLogout, onRefreshToken, accessToken, allAppNames, minimize, setMinimize }) => {
    const [openForm, setOpenForm] = useState(false);
    const [isClickedButton,setIsClickedButton]=useState(false);
    

    const handleAddApp = () => {
     
        setIsClickedButton(true);

        //reset click animation after 200ms
        setTimeout(() => {
          setIsClickedButton(false);
        }, 200);

        setOpenForm(true);
    }
    // console.log(minimize, "minimized")
    return (
        <div>
            {!accessToken ? <Notfoundpage /> :
                (<div className='appDashboard'>
                    <div className='sidebar'>
                        {minimize ? <ApplicationLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <ApplicationMinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}

                    </div>
                    <div className='content'>
                        <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                            <ApplicationNavbar />
                        </div>

                        <div>
                            <div className={`mainContentnew ${!minimize ? 'minimized' : ''}`}>

                                {minimize ? 
                                <div className='newApp' >
                                    <div><img src={appIcon} alt='add application' /></div>
                                    <div id='add-newApp'>To see Organisational Security Landscape please add Applications</div>
                                    <div className={`add-newAppBtn ${isClickedButton ? "clicked": ""}`}>
                                        <button className={`add_app_btn1 ${isClickedButton ? "clicked": ""}`} onClick={handleAddApp}>Add New Application</button>
                                    </div>
                                </div> :
                                <div className='newApp-min'>
                                        <div><img src={appIcon} alt='add application' /></div>
                                        <div id='add-newApp'>To see Organisational Security Landscape please add Applications</div>
                                        <div className={`add-newAppBtn ${isClickedButton ? "clicked": ""}`}>
                                            <button className={`add_app_btn1 ${isClickedButton ? "clicked": ""}`} onClick={handleAddApp}>Add New Application</button>
                                        </div>

                                </div>
                                }
                            </div>
                            <div>
                                {openForm && <VerticalStepper setOpenForm={setOpenForm} allAppNames={allAppNames} onRefreshToken={onRefreshToken}/>}
                            </div>
                        </div>
                    </div>
                </div>)
            }
        </div>
    );
};

export default AddApplication;











