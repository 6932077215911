import React, { useState } from 'react';
import yelloDot from "Images/yellowDot.svg";
import whiteDot from "Images/whiteDot.svg";
import 'Styles/DeveloperMode/Pagination/Pagination.css'

const PaginationDots = ({ currentPage1, totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(currentPage1);

  const goNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
      setCurrentPage(currentPage + 1)
    }
  };

  const goPrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
      setCurrentPage(currentPage - 1)
    }
  };

  const goToPage = (page) => {
    onPageChange(page);
    setCurrentPage(page)
  };

  return (
    <div className="nextPageDot">
      <button onClick={goPrevPage} disabled={currentPage === 1}>
       {/* {"<"} */}
      </button>
      {Array.from({ length: totalPages }, (_, index) => (
        <img 
          key={index + 1} 
          src={currentPage === index + 1 ? yelloDot : whiteDot} 
          alt={currentPage === index + 1 ? "Yellow Dot" : "White Dot"}
          onClick={() => goToPage(index + 1)} 
          className="dot"
        />
      ))}
      <button onClick={goNextPage} disabled={currentPage === totalPages}>
       {/* {">"} */}
      </button>
    </div>
  );
};

export default PaginationDots;
