import React, { useEffect, useState } from 'react';
import LeftSidebar from 'Components/DeveloperMode/LeftSidebar/LeftSidebar';

import Navbar from 'Components/DeveloperMode/MainContent/Navbar';
import 'Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import ListOfRuns from 'Components/DeveloperMode/MainContent/ListOfRuns';
import Calendar from 'Components/DeveloperMode/PopUpUtils/Calender';
import RunApplication from 'Components/DeveloperMode/PopUpUtils/Run_application';
import MinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/MinimizeLeftBar';
import ChoosePreference from 'Components/DeveloperMode/PopUpUtils/ChoosePreference';
import {useNavigate } from 'react-router-dom';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';

const FlowLevelDashboard = ({ onLogout, onRefreshToken, minimize, setMinimize ,setPreventWorkflowRender,preventWorkflowRender }) => {
    const [showCalender, setShowCalender] = useState(false);
    const [runApplication, setRunApplication] = useState(false);
    const [openForm,setOpenForm]=useState(false);
    const [errorFlag,setErrorFlag]=useState(false);
    const navigate = useNavigate();
    const {showSnackbar}=useSnackbarContext();
    
    useEffect(() => {
      if (errorFlag) {
        navigate("/");
        showSnackbar(`The server is currently down for maintenance.`, {
          variant: 'error',
          className: 'snackbar-error',
          autoHideDuration: 2500,
        });
    
        // Reset the error flag 
        setErrorFlag(false);
      }
    }, [errorFlag, navigate, showSnackbar]);

    const toggleRunButton = () => {
        setRunApplication(prevState => !prevState);
    }

    const togglecalender = () => {
        setShowCalender(prevState => !prevState)
    }
    return (
        <div className='appDashboard'>
            <div className='sidebar'>
                {minimize ? <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} preventWorkflowRender={preventWorkflowRender}/> : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} preventWorkflowRender={preventWorkflowRender} />}
            </div>
            <div className='content'>
                <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                    <Navbar />
                </div>
                <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
                    <ListOfRuns setErrorFlag={setErrorFlag} togglecalender={togglecalender} toggleRunButton={toggleRunButton} onRefreshToken={onRefreshToken} setOpenForm={setOpenForm}  setPreventWorkflowRender={setPreventWorkflowRender}/>
                </div>
                {showCalender ? <Calendar showCalender={showCalender} setShowCalender={setShowCalender} /> : ''}
                {runApplication ? <RunApplication runApplication={runApplication} setRunApplication={setRunApplication} /> : ''}
                {openForm ? <ChoosePreference setErrorFlag={setErrorFlag} setOpenForm={setOpenForm} onRefreshToken={onRefreshToken} /> : ""}
            </div>
        </div>
    );
}

export default FlowLevelDashboard;