import React, { useEffect, useState } from 'react';
import { useWorkflowDetails } from 'Components/StateManagement/WorkflowDetailsContext';
import { Link, useNavigate } from 'react-router-dom';
import 'Styles/DeveloperMode/AppDashboard/ListOfApplication.css';
import appIcon from 'Images/app_add.svg'
import AppCVSSTrend from 'Components/DeveloperMode/Graph_content/AppCVSSTrend';
import AppCWEMatric from 'Components/DeveloperMode/Graph_content/AppCWEMatric';
import 'Styles/DeveloperMode/DeveloperMode.css';
import arrow from 'Images/Vector (2).svg';
import VerticalStepper from 'Components/CisoMode/VerticalStepper';
import add from 'Images/add.svg';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';
import { useSnackbar } from 'notistack';

const ListOfApplication = ({setErrorFlag, onRefreshToken, setAllAppNames, allAppNames }) => {
    const [activeItem, setActiveItem] = useState('9.5');
    const [applications, setApplications] = useState([]);
    const navigate = useNavigate();
    const { setApp_id } = useWorkflowDetails();
    const { setAppname } = useWorkflowDetails();
    const [displayItems, setDisplayItems] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [accessTokenUpdated, setAccessTokenUpdated] = useState('');
    const [refresh, setRefresh] = useState(false);
    const {enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {showSnackbar}=useSnackbarContext();
    
    const handleItemClick = (item) => {
        setActiveItem(item);
    }


    useEffect(() => {
        fetchApplications();
    }, [setApp_id, refresh]);

    useEffect(() => {
        if (accessTokenUpdated) {
            fetchApplications();
        }
    }, [accessTokenUpdated]);

    const apiUrl = process.env.REACT_APP_URL;
    const localToken = localStorage.getItem('accessToken');

    const fetchApplications = async () => {
        try {
            const response = await fetch(`${apiUrl}/resources/apps`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                }
            });

            if (response.ok) {
                const data = await response.json();
                const reverseData = data.reverse()
                setApplications(reverseData);
                setAllAppNames(reverseData);
                //check application is present or not
                const show_name = reverseData.some(data => data.app_name);
                setDisplayItems(show_name);
                setAllAppNames(data);
            } else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            }  else if (response.status === 404) {
                showSnackbar(`Page Not Found`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })

            } else if (response.status === 400) {

                showSnackbar(`Bad Request`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }
              else if (response.status === 500) {
        
                showSnackbar(`Internal Server Error`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }
              
            

        } catch (error) {
            console.error('Error fetching applications:', error);
            if (error.message.includes("ERR_CONNECTION_REFUSED") || error.message.includes("Failed to fetch")) {
                setErrorFlag(true);
            }
        }
    };


    const handleApplicationClick = async (appId, appname, url) => {
        try {
            // Update the state immediately
            setApp_id(appId);
            setAppname(appname);
            localStorage.setItem('url', url);

            const response = await fetch(`${apiUrl}/resources/apps/${appId}/flows`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                const hasFlowName = data.some(flow => flow.flow_name);

                // Ensure the state is set before navigating
                setRefresh(prev => !prev);

                if (hasFlowName) {
                    navigate(`/application/${appname}`);
                } else {
                    navigate('/add_flow');
                }
            } else if (response.status === 400 || response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            } else if (response.status === 404) {
                showSnackbar(`Page Not Found`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })

            }
        } catch (error) {
            console.error('Error fetching flows:', error);
            if (error.message.includes("ERR_CONNECTION_REFUSED") || error.message.includes("Failed to fetch")) {
                setErrorFlag(true);
            }
        }
    };


    const handleAddApplication = () => {
        navigate("/add_application");
    }

    const handleAddApp = () => {
        setOpenForm(true);
    }

    return (
        <div>
            {displayItems ? (
                <div className='applevel'>
                    <div className='applevel-row1'>
                        <div className={`col-1t ${activeItem === 'New Threat' ? 'active' : ''}`} onClick={() => handleItemClick('New Threat')}>
                            <p id='col-txt'> New Threats</p>
                        
                        </div>
                        <div className={`col-2t ${activeItem === 'Total Threat' ? 'active' : ''}`} onClick={() => handleItemClick('Total Threat')}>
                            <p id='col-txt'> Total Threats</p>
                          
                        </div>
                        <div className={`col-3t ${activeItem === 'Resolved' ? 'active' : ''}`} onClick={() => handleItemClick('Resolved')}>
                            <p id='col-txt'> Resolved</p>
                          
                        </div>
                        <div className={`col-4t ${activeItem === 'Compliance Issues' ? 'active' : ''}`} onClick={() => handleItemClick('Compliance Issues')}>
                            <p id='col-txt'> Compliance Issues</p>
                        </div>

                        <div className={`col-5t ${activeItem === '9.5' ? 'active' : ''}`} onClick={() => handleItemClick('9.5')}> </div>
                    </div>
                    <div className='applevel-row2'>Performance</div>
                    <div className='applevel-row3'>
                        <div className='row3-grp1'><AppCVSSTrend /> </div>
                        <div className='row3-grp2'><AppCWEMatric /></div>
                    </div>

                    <div className='applevel-row4' style={{ backgroundColor: "#F6F6F3" }}>
                        <div id='newApp'>
                            <div style={{ marginLeft: "10px", marginTop: "10px" }}> Applications</div>
                            <button style={{ width: '30px', height: '30px', margin: '6px 5px 0px 5px ', padding: "6px 6px 4px 6px", backgroundColor: "#fff", border: "1px solid #f0efef", borderRadius: "8px", cursor: "pointer", boxShadow: '0 0 10px rgba(0,0,0,0.04)' }} onClick={handleAddApplication}><img src={add} alt='add application' /> </button>
                        </div>
                        <div className='appnames'>
                            <div className="app-grid">
                                {applications.map(application => (
                                    <div key={application._id} className='tiles'>
                                        {/* if only application name is present with no workflows */}
                                        {!application.OWASP_top_10_info && application.app_name ? (
                                            <div>
                                                <Link className="applist" style={{width:"100%"}} to={`/add_flow`} onClick={() => handleApplicationClick(application._id, application.app_name, application.url)} >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                       <div>{application.app_name}</div>  <img src={arrow} alt='arrow' style={{ marginRight: "10px", width: '15px' }} />
                                                    </div>
                                                    <div id='grp_count'>

                                                        <p id='noRuns' style={{ fontSize: "12px", fontFamily: 'DM Sans', display: 'flex', justifyContent: 'left', marginRight: '100px', color: '#4E4E4E' }}>Perform Runs</p>
                                                       
                                                    </div>
                                                </Link>
                                            </div>
                                        ) : (
                                            // application names with workflows or data
                                            <div>
                                                <Link className="applist" style={{width:"100%"}} to={`/application/${application.app_name}`} onClick={() => handleApplicationClick(application._id, application.app_name, application.url)} >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className='extend-app-name'>{application.app_name}</div>  <img src={arrow} alt='arrow' style={{ marginRight: "10px", width: '15px' }} />
                                                    </div>
                                                    <div id='grp_count'>
                                                        {Object.entries(application.OWASP_top_10_info)
                                                            .filter(([key, value]) => value > 0).slice(0, 3)
                                                            .map(([key, value], index) => (
                                                                <pre key={index} id='Alist'>{`${key}(${value})`}</pre>
                                                            ))}
                                             
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
              <div style={{marginTop:'20px',height:'684px',width:'98.2%',backgroundColor:'#f6f6f6',borderRadius:'10px'}}>
                    <div className='newApp'>
                        <div><img src={appIcon} alt='add application' /></div>
                        <div id='add-newApp'>To see Organisational Security Landscape please add Applications</div>
                        <div className='add-newAppBtn'>
                            <button className='add_app_btn1' onClick={handleAddApp}>Add Application</button>
                        </div>

                    </div>
                    <div>
                        {openForm && <VerticalStepper setOpenForm={setOpenForm} allAppNames={allAppNames} />}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ListOfApplication;