
import React, { useState } from 'react';
import 'Styles/DeveloperMode/Navbar/SearchBar.css'

import text from 'Images/Text.svg';
import searchBlack from 'Images/searchBlack.svg';
import searchwhite from 'Images/searchwhite.svg';

import textlight from 'Images/Textlight.svg';

const SearchBar = ({ theme }) => {

  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Search Term:', searchTerm);
    setSearchTerm("")
  };



  return (
    <form className="searchBar"  onSubmit={handleSubmit}>
    <div className={`searchInput ${theme === 'dark' ? 'dark' : 'light'}`}>
      <button type="submit">
        <img src={theme === 'dark' ? searchwhite : searchBlack} alt="Search" />
      </button>
      <input
        type="text"
        placeholder="Search..."
        className={theme === 'dark' ? 'dark' : 'light'}
        value={searchTerm}
        onChange={handleChange}
      />
      {/* <p> */}
      <img id='textlight' src={theme === 'dark' ? text : textlight} alt="Search" />
      {/* </p> */}
    </div>
  </form>
  );
};

export default SearchBar;

