// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-body{
    font-family:'DM sans'; 
    background-color: #D3D3D3; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
}
.error-container{
    text-align: center; 
    background-color: #fff; 
    padding: 20px; 
    border-radius: 10px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}
.error-container h1 { 
    color: #ff5733; 
}
.error-container p{
    font-size: 22px; 
    color: #333; 
    margin-bottom: 20px; 
}
.error-container button{
    text-decoration: none; 
    background-color: #ff5733; 
    color: #fff; 
    padding: 10px 20px; 
    font-weight: bold; 
    cursor: pointer;
    border-radius: 10px; 
    border: none;
}
.error-container button:hover { 
    background-color: #e6482e; 
}`, "",{"version":3,"sources":["webpack://./src/Styles/DeveloperMode/PopUpUtils/Notfound.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;AAC5C;AACA;IACI,cAAc;AAClB;AACA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".error-body{\n    font-family:'DM sans'; \n    background-color: #D3D3D3; \n    display: flex; \n    justify-content: center; \n    align-items: center; \n    height: 100vh; \n}\n.error-container{\n    text-align: center; \n    background-color: #fff; \n    padding: 20px; \n    border-radius: 10px; \n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); \n}\n.error-container h1 { \n    color: #ff5733; \n}\n.error-container p{\n    font-size: 22px; \n    color: #333; \n    margin-bottom: 20px; \n}\n.error-container button{\n    text-decoration: none; \n    background-color: #ff5733; \n    color: #fff; \n    padding: 10px 20px; \n    font-weight: bold; \n    cursor: pointer;\n    border-radius: 10px; \n    border: none;\n}\n.error-container button:hover { \n    background-color: #e6482e; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
