import React, { createContext, useContext, useEffect, useState } from 'react';

const WorkflowDetailsContext = createContext();

export const WorkflowDetailsProvider = ({ children }) => {
  const [app_id, setApp_id] = useState("");
  const [workflowDetails, setWorkflowDetails] = useState("");
  const [workflowDetail, setWorkflowDetail] = useState("");
  const [allContextData,setAllContextData]=useState("");
  const [flowId,setFlowId]=useState('');
  const [runId,setRunId]=useState('');
  const [flowName,setFlowName]=useState('');
  const [runName,setRunName]=useState('');
  const [textareaRows, setTextareaRows] = useState(30); 
  const [appname,setAppname]=useState('');
  const [combineCWE, setCombineCWE] = useState('');

  useEffect(() => {
    localStorage.setItem('appname', appname);
}, [appname]);
  useEffect(() => {
    localStorage.setItem('app_id', app_id);
}, [app_id]);

  useEffect(() => {
    localStorage.setItem('flowId', flowId);
}, [flowId]);

useEffect(() => {
  localStorage.setItem('runId', runId);
}, [runId]);

useEffect(() => {
  localStorage.setItem('flowName', flowName);
}, [flowName]);
useEffect(() => {
  localStorage.setItem('runName', runName);
}, [runName]);

useEffect(()=>{
  sessionStorage.setItem('CWE-Name',combineCWE)
},[combineCWE]);

  return (
    <WorkflowDetailsContext.Provider 
    value={{ 
            workflowDetails, 
            setWorkflowDetails,
            workflowDetail, 
            setWorkflowDetail,
            allContextData,
            setAllContextData,
            flowId,
            setFlowId,
            runId,setRunId,
            flowName,
            setFlowName,
            runName,
            setRunName,
            textareaRows,
            setTextareaRows,
            app_id,
            setApp_id ,
            appname,
            setAppname,
            combineCWE,
            setCombineCWE
            }}>
      {children}
    </WorkflowDetailsContext.Provider>
  );
};

export const useWorkflowDetails = () => useContext(WorkflowDetailsContext);
