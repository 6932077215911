import React from 'react'
import 'Components/DeveloperMode/Fuzzer/CWETagsPopUp.css'; 

const CWETagsPopUp = ({ show, onClose, data }) => {
    if (!show) {
        return null;
      }
    
      return (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={onClose}>&times;</span>
            <table>
              <thead>
                <tr>
                  <th>Tag</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {data.map(([tagType, count]) => (
                  <tr key={tagType}>
                    <td>{tagType}</td>
                    <td>{count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    };
    
export default CWETagsPopUp
