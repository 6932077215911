import React, { useState, useEffect } from 'react';
import 'Styles/DeveloperMode/PopUpUtils/Confirm_fuzz.css';
import close from 'Images/close.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import '@fontsource/dm-sans';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';
import "Styles/DeveloperMode/Notification/SnackbarMUI.css";
import { useWorkflowDetails } from 'Components/StateManagement/WorkflowDetailsContext';
import { useNavigate } from 'react-router-dom';


function ConfirmFuzz_Modal({ setCompleteReport,setShowNotFoundPage, onRefreshToken, collectRawDataId, CWEName, CWEDesc, isOpen, setIsOpen, CWEUrl, collectAPI, OWASPName, setViewReport }) {

  const [selectRadio, setSelectRadio] = useState('prob');
  const [showApiDropdown, setShowApiDropdown] = useState(false);
  const [personName, setPersonName] = useState([]);
  const apiUrl = process.env.REACT_APP_URL;
  const [isFuzzButtonDisabled, setIsFuzzButtonDisabled] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); //notification animation
  const { showSnackbar } = useSnackbarContext();
  const { app_id, setApp_id } = useWorkflowDetails();
  const { flowId, setFlowId } = useWorkflowDetails();
  const { runId, setRunId } = useWorkflowDetails();

  const { combineCWE } = useWorkflowDetails()
  const [universalNum, setUniversalNum] = useState(0);
  const [similarNum, setSimilarNum] = useState(0);
  const [incrementalNum, setIncrementalNum] = useState(0);
  const navigate = useNavigate();
  const [isClickedButton,setIsClickedButton]=useState(false);



  useEffect(() => {
    const storedFlowId = localStorage.getItem('flowId');
    const storedRunId = localStorage.getItem('runId');
    if (storedFlowId) {
      setFlowId(storedFlowId);
    }
    if (storedRunId) {
      setRunId(storedRunId);
    }
  }, [setFlowId, setRunId]);

  useEffect(() => {
    const storedApp_id = localStorage.getItem('app_id');
    if (storedApp_id) {
      setApp_id(storedApp_id);
    }
  }, [setApp_id]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = collectAPI;

  const handleChange = (event) => {
    const { value } = event.target;
    setPersonName(value);
  };

  const handleOptionChange = (e) => {
    const { value } = e.target;
    setSelectRadio(value);

    if (value === 'exploit') {
      setShowApiDropdown(true);
    } else {
      setShowApiDropdown(false);
    }
  };
  // console.log("threat idss",JSON.stringify({ raw_data_ids: collectRawDataId,no_of_universal_values:5,no_of_generate_similar_values:5,no_of_generate_incremetal_values:5,cwe_info:combineCWE },null,2))

  // console.log(combineCWE, "combineCWE")

  const localToken = localStorage.getItem('accessToken');
  const url = `${apiUrl}/fuzzer/apps/${app_id}/flows/${flowId}/runs/${runId}/fuzz/${combineCWE}`;
  // const persistentSnackbars = [];
  const handleFuzzing = async () => {
    setIsClickedButton(true);

    //reset click animation after 200ms
    setTimeout(() => {
      setIsClickedButton(false);
    }, 200);
    
    setTimeout(() => {
      setIsOpen(false);
    }, 1500)
    if (selectRadio === 'prob') {

      const snackbarId = enqueueSnackbar('Fuzzing in progress... This might take a few moments. Please wait.', {
        variant: 'info',
        action: <button onClick={() => closeSnackbar(snackbarId)} style={{ backgroundColor: '#ddffff', border: 'none' }}>
          <img src={close} alt='close' style={{ width: '15px', height: '15px', cursor: 'pointer' }} />
        </button>,
        persist: true,
        className: 'snackbar-info'
      });
      // persistentSnackbars.push(snackbarId);

      setIsFuzzButtonDisabled(true); // Disable the button after click
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localToken}`,
          },
          body: JSON.stringify({
            raw_data_ids: collectRawDataId,
            no_of_universal_values: universalNum,
            no_of_generate_similar_values: similarNum,
            no_of_generate_incremetal_values: incrementalNum,
            cwe_info: combineCWE
          }, null, 2)
        })

        if (response.ok) {
          const result = await response.json();
          // console.log(JSON.stringify(result))
          const reportId = result['detail'];
          setCompleteReport(reportId);
          // console.log(reportId)
          if (reportId === "Fuzzing process completed") {

            showSnackbar('All done! The fuzzing process is complete.', { variant: 'success', className: 'snackbar-success' });
          
            setViewReport(true);
          }
          if (reportId === "Internal server error") {
            showSnackbar('Fuzzing process failed due to a technical error. Please try again.', { variant: 'error', className: 'snackbar-error' });
          }
        }
        else if (response.status === 401) {
          const result = await onRefreshToken();
          if (result === "success") {
            localToken = localStorage.getItem('accessToken');
          }
          else {
            navigate('/');
          }
        }
        else if (response.status === 404) {
          setShowNotFoundPage(true);
        }
        else if (response.status === 500) {

          showSnackbar(`Internal Server Error`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })

        }


      }
      catch (error) {
        console.log("Error during fuzzing ", error)
        showSnackbar('An error occurred during fuzzing.Please try again.', { variant: 'error', className: 'snackbar-error' });
      }
    } else {
      // case for exploit radio button
    }

  }

  const handleNum = (e, type) => {
    const { value } = e.target;

    if (value < 0) return null;

    if (type === 'universal') setUniversalNum(value);
    else if (type === 'similar') setSimilarNum(value);
    else if (type === 'incremental') setIncrementalNum(value);
  };

  // console.log({universalNum,similarNum,incrementalNum})

  return (
    <div className='popupConfirm-container' >
        <div className={`popup-container ${isOpen ? 'show' : ''}`}>
          <div className="popup">

            <div>
              <div className="popup-header">
                <div className='header'>Confirm Now</div>
                <button className="close-btn" onClick={() => setIsOpen(false)}>
                  <img src={close} alt='close' />
                </button>
              </div>

              <div className="popup-body">

                <div className='trimCWE'>
                  <div id='trimCWE'>{OWASPName}</div>
                  <p id='desc'>{CWEName} : {CWEDesc}</p>
                  <a href={CWEUrl} target="_blank" rel="noreferrer" >{CWEUrl}</a>
                </div>

              </div>
            </div>

            <div>
              {/* <form> */}
              <div className='radioForm'>
                <div className='prob'>
                  <input type='radio' id='prob' name='prob' value='prob'
                    onChange={handleOptionChange}
                    checked={selectRadio === 'prob'} />
                  Probe
                </div>

                <div className='exploit'>
                  <input type='radio' id='exploit' name='exploit' value='exploit'
                    onChange={handleOptionChange}
                    checked={selectRadio === 'exploit'} />
                  Exploit
                </div>
              </div>

              {showApiDropdown && CWEDesc && (
                <div className='formControl'>
                  <div >
                    <FormControl sx={{ width: 480, border: 'none' }} className='select' >
                      <InputLabel id="demo-multiple-checkbox-label">Select API</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Select API" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.includes(name)} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

              )}
              {!showApiDropdown ?
              (<div className='inputValue' >
                <label htmlFor='no_of_universal_values'>
                  Predefine values count :
                  <input
                    type='input'
                    placeholder='no_of_universal_values'
                    value={universalNum ||''}
                    onChange={(e) => handleNum(e, 'universal')}
                    name='no_of_universal_values'
                  />
                </label>
                <label htmlFor='no_of_generate_similar_values'>
                  Generating similar values count:
                  <input
                    type='input'
                    placeholder="no_of_generate_similar_values"
                    value={similarNum || ''}
                    onChange={(e) => handleNum(e, 'similar')}
                    name='no_of_generate_similar_values'
                  />
                </label>

                <label htmlFor='no_of_generate_incremetal_values'>
                  Generating incremental values count:
                  <input
                    type='input'
                    placeholder="no_of_generate_incremetal_values"
                    value={incrementalNum || ''}
                    onChange={(e) => handleNum(e, 'incremental')}
                    name='no_of_generate_incremetal_values'
                  />
                </label>

              </div>):''}

              <div className='submitButton'>
                <button type='submit' className={`fuzzNowBtn ${isClickedButton ? "clicked": ""}`} onClick={handleFuzzing} disabled={isFuzzButtonDisabled}>Verify Now</button>
              </div>

            </div>

          </div>
        </div>
    </div>
  );
}

export default ConfirmFuzz_Modal;
