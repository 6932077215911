import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'Styles/DeveloperMode/AppDashboard/MinimizeLeftBar.css';
import logo from 'Images/apisecuristLogo.svg';
import vector from 'Images/overview1.svg';
import icon1 from 'Images/data.svg';
import icon2 from 'Images/note.svg';
import icon3 from 'Images/maximize-2.svg';
import icon4 from 'Images/align-left.svg';
import icon5 from 'Images/Icon (from Tabler.io) (4).svg';
import icon6 from 'Images/logout.svg';
import expand from 'Images/arrow button.svg';
import User from 'Components/UserAuthentication/User';
import { useWorkflowDetails } from 'Components/StateManagement/WorkflowDetailsContext';

const MinimizeLeftBar = ({ onLogout, setMinimize, minimize,preventWorkflowRender }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { appname, setAppname } = useWorkflowDetails();
    const { workflowId, runName } = useParams();
    const [activeItem, setActiveItem] = useState('Overview');


    useEffect(() => {
        const storeapp = localStorage.getItem('appname');
        if (storeapp) {
            setAppname(storeapp);
        }
    }, [setAppname]);

      useEffect(() => {

        const pathname = location.pathname;
        let currentActiveItem = '';
        if (pathname.includes(`/application/${appname}`)|| pathname.includes(`report`)) {
            currentActiveItem = 'Overview';
        } else if (pathname.includes(`/${appname}/Workflows`)) {
            currentActiveItem = 'Flows';
        // } else if (pathname.includes(`/${appname}/Reports`)) {
        } else if (pathname.includes(`/Reports`)) {
            currentActiveItem = 'Reports';
        } else if (pathname.includes(`/${appname}/activity-planner`)) {
            currentActiveItem = 'Run Activity & Planner';
        } else if (pathname.includes(`/${appname}/threat-matrix`)) {
            currentActiveItem = 'Threat Matrix';
        } else if (pathname.includes(`/${appname}/settings`)) {
            currentActiveItem = 'Settings';
        }
        setActiveItem(currentActiveItem);
    // Set the active item class based on the updated active item
        const menuItems = document.querySelectorAll('menu-item-min');
        menuItems.forEach(item => {
          if (item.textContent.trim() === activeItem) {
            item.classList.add('active');
          } else {
            item.classList.remove('active');
          }
        });
      }, [location.pathname,appname]);

      const handleItemClick = (item) => {
        switch (item) {
          case 'Overview':
            navigate(`/dashboard`);
            break;
            case 'Flows':
                // navigate(`/${appname}/Workflows`);
                if(preventWorkflowRender){
                    navigate(`/${appname}/Workflows`)
                  }
                break;
          case 'Reports':
            // navigate(`/${appname}/Reports`);
            navigate(`/Reports`);
            break;
          default:
            break;
        }
      };

    const handleLogout = () => {
        onLogout();
        navigate('/');
    };

    const handleMinimize = () => {
        setMinimize(true);
    }

    return (

        <div className='minimize-menu'>
        <div className='minimize-menu-form'>
            <img className='WasLogo-min' src={logo} alt='logo' />
            <div className='expand-arrow'>
            <img className='expand-sidebar' src={expand} alt='icon' onClick={handleMinimize} />
            </div>
            <div className='All-item-min'>
                <div>
                    <div className={`menu-item-min ${activeItem === 'Overview' ? 'active' : ''}`} onClick={() => handleItemClick('Overview')}>
                        <img className='WasImg' src={vector} alt='vector' />
                    </div>
                    <div className={`menu-item-min ${activeItem === 'Flows' ? 'active' : ''}`} onClick={() => handleItemClick('Flows')}>
                        <img className='WasImg' src={icon1} alt='icon1' />
                    </div>
                    <div className={`menu-item-min ${activeItem === 'Reports' ? 'active' : ''}`} onClick={() => handleItemClick('Reports')}>
                        <img className='WasImg' src={icon2} alt='icon2' />
                    </div>
                    <div className={`menu-item-min ${activeItem === 'Run Activity & Planner' ? 'active' : ''}`} onClick={() => handleItemClick('Run Activity & Planner')}>
                        <img className='WasImg' src={icon3} alt='icon3' />
                    </div>
                    <div className={`menu-item-min ${activeItem === 'Threat Matrix' ? 'active' : ''}`} onClick={() => handleItemClick('Threat Matrix')}>
                        <img className='WasImg' src={icon4} alt='icon4' />
                    </div>
                </div>
            </div>
            <div className='second-menu-min'>
                <div className='user-role1'>
                    <User minimize={minimize} />
                </div>
                <div className='user-role'>
                    <div className={`menu-item-min ${activeItem === 'Settings' ? 'active' : ''}`} onClick={() => handleItemClick('Settings')}>
                        <img className='WasImg' src={icon5} alt='icon5' />
                    </div>
                    <div className='menu-item-min' onClick={handleLogout}>
                        <img className='WasImg' src={icon6} alt='icon6' style={{ color: '#EB5050' }} />
                    </div>
                </div>
            </div>
        </div>
    </div>

    );
}

export default MinimizeLeftBar;



