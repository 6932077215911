import React, { useEffect, useState } from 'react';
import 'Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import LeftSidebar from 'Components/DeveloperMode/LeftSidebar/LeftSidebar';
import Navbar from 'Components/DeveloperMode/MainContent/Navbar';
import MinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/MinimizeLeftBar';
import PreProcessFuzzer from 'Components/DeveloperMode/Fuzzer/PreProcessFuzzer';
import ConfirmFuzz_Modal from 'Components/DeveloperMode/PopUpUtils/ConfirmFuzz_Modal';
import ConfirmAll_modal from 'Components/DeveloperMode/PopUpUtils/ConfirmAll_modal';
import Notfoundpage from 'Components/DeveloperMode/FlowDB/Notfoundpage';
import {useNavigate } from 'react-router-dom';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';

const PreFuzzing = ({ onLogout, onRefreshToken, accessToken, minimize, setMinimize,setCompleteReport }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [CWEName, setCWEName] = useState('');
    const [CWEDesc, setCWEDesc] = useState('');
    const [CWEUrl, setCWEUrl] = useState('');
    const [collectRawDataId, setCollectRawDataId] = useState([]);
    const [OWASPName, setOWAPSName] = useState([]);
    const [collectAPI, setCollectAPI] = useState([]);
    const [allThreats, setAllThreats] = useState([]);
    const [viewReport, setViewReport] = useState(false);
    const [preventWorkflowRender, setPreventWorkflowRender] = useState(false);
    const [showNotFoundPage,setShowNotFoundPage]=useState(false);
    const [errorFlag,setErrorFlag]=useState(false);
    const navigate = useNavigate();
    const {showSnackbar}=useSnackbarContext();
    
    useEffect(() => {
      if (errorFlag) {
        navigate("/");
        showSnackbar(`The server is currently down for maintenance.`, {
          variant: 'error',
          className: 'snackbar-error',
          autoHideDuration: 2500,
        });
    
        // Reset the error flag 
        setErrorFlag(false);
      }
    }, [errorFlag, navigate, showSnackbar]);

    const openModal = () => {
        setIsModalOpen(true);
    };
    const openModal1 = () => {
        setIsModalOpen1(true);
    }

    return (
        <>
        {!showNotFoundPage ?
        <div className='appDashboard'>
            <div className='sidebar'>
                {minimize ?
                    <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} preventWorkflowRender={preventWorkflowRender} />
                    : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} preventWorkflowRender={preventWorkflowRender} />}
            </div>
            <div className='content'>
                <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                    <Navbar minimize={minimize} />
                </div>
                <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
                    <PreProcessFuzzer
                        onRefreshToken={onRefreshToken}
                        accessToken={accessToken}
                        minimize={minimize}
                        openModal={openModal}
                        setCWEName={setCWEName}
                        setCWEDesc={setCWEDesc}
                        setUrl={setCWEUrl}
                        setCollectRawDataId={setCollectRawDataId}
                        // setCombineCWE={setCombineCWE}
                        openModal1={openModal1}
                        collectAPI={setCollectAPI}
                        setAllThreats={setAllThreats}
                        setOWAPSName={setOWAPSName}
                        viewReport={viewReport}
                        setViewReport={setViewReport}
                        // combineCWE={combineCWE}
                        setPreventWorkflowRender={setPreventWorkflowRender}
                        setErrorFlag={setErrorFlag}
                    />
                </div>
                {isModalOpen &&
                    <div >
                        <ConfirmFuzz_Modal
                            isOpen={isModalOpen}
                            setIsOpen={setIsModalOpen}
                            CWEName={CWEName}
                            CWEDesc={CWEDesc}
                            CWEUrl={CWEUrl}
                            collectRawDataId={collectRawDataId}
                            // combineCWE={combineCWE}
                            collectAPI={collectAPI}
                            OWASPName={OWASPName}
                            setViewReport={setViewReport}
                            onRefreshToken={onRefreshToken}
                            setShowNotFoundPage={setShowNotFoundPage}
                            setCompleteReport={setCompleteReport}
                         
                        />
                    </div>
                }
                {isModalOpen1 &&
                    <ConfirmAll_modal
                        isOpen={isModalOpen1}
                        setIsOpen={setIsModalOpen1}
                        OWASPName={OWASPName}
                        onRefreshToken={onRefreshToken}
                        setViewReport={setViewReport}
                        setShowNotFoundPage={setShowNotFoundPage}
                        
                    />
                }
            </div>
         
        </div>
        :
        (<Notfoundpage />)}
        </>
    )
}

export default PreFuzzing
