import React from 'react';
import CisoLeftSidebar from 'Components/DeveloperMode/ApplicationDashboard/CisoLeftSidebar';
import ApplicationNavbar from 'Components/DeveloperMode/ApplicationDashboard/ApplicatonNavbar';
import MinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/MinimizeLeftBar';


const CisoMode = ({onLogout,onRefreshToken,minimize,setMinimize }) => {
 
  return (
    <div className='appDashboard'>
    <div className='sidebar'>
        {/* <CisoLeftSidebar onLogout={onLogout} minimize={minimize} setMinimize={setMinimize} /> */}
        {minimize ?  <CisoLeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}

    </div>
    <div className='content'>
        <div className="navbar-container">
            <ApplicationNavbar />
        </div>
        <div className='mainContent'>
        </div>
    </div>
</div>
  );
};

export default CisoMode;








