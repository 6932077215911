import React from 'react'
import 'Styles/DeveloperMode/PopUpUtils/Run_application.css';
import close from 'Images/close.svg';
const Run_application = ({ runApplication, setRunApplication }) => {
    return (
        <div className='blur-background-run_application'>
            <div className='run_application'>
                <div className='run-header'>
                    <p>Run Now</p>
                    <button className='closeRun' onClick={() => setRunApplication(false)}  >
                        <img src={close} alt='close0' />
                    </button>
                </div>
                <div className='input-run_app'>
                    <label htmlFor='run_app' >Enter run name</label>
                    <input type='text' id='run_app' name='run_app'  placeholder='application name' />
                    <button  className='run-now'> Run Now</button>
                </div>
            </div>
        </div>
    )
}

export default Run_application
