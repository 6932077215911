import React, { useEffect, useState } from 'react';
import LeftSidebar from 'Components/DeveloperMode/LeftSidebar/LeftSidebar';
import MainContent from 'Components/DeveloperMode/MainContent/MainContent';
import Navbar from 'Components/DeveloperMode/MainContent/Navbar';
import 'Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import MinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/MinimizeLeftBar';
import Notfoundpage from 'Components/DeveloperMode/FlowDB/Notfoundpage';
import 'Styles/DeveloperMode/PopUpUtils/Notfound.css';
import {useNavigate } from 'react-router-dom';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';

const AppDashboard = ({ onLogout, onRefreshToken, accessToken, minimize, setMinimize, setPreventWorkflowRender,preventWorkflowRender }) => {
    const [notFoundPage, setNotFoundPage] = useState(false);
    const [errorFlag,setErrorFlag]=useState(false);
    const navigate = useNavigate();
    const {showSnackbar}=useSnackbarContext();
    
    useEffect(() => {
      if (errorFlag) {
        navigate("/");
        showSnackbar(`The server is currently down for maintenance.`, {
          variant: 'error',
          className: 'snackbar-error',
          autoHideDuration: 2500,
        });
    
        // Reset the error flag 
        setErrorFlag(false);
      }
    }, [errorFlag, navigate, showSnackbar]);
    
    return (
        <>
         { !notFoundPage ?(  
           <div className='appDashboard'>
            <div className='sidebar'>
                {minimize ? <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize}  preventWorkflowRender={preventWorkflowRender} /> : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} preventWorkflowRender={preventWorkflowRender} />}
            </div>
            <div className='content'>
                <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                    <Navbar minimize={minimize} />
                </div>
                <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
                    <MainContent setErrorFlag={setErrorFlag} onRefreshToken={onRefreshToken} accessToken={accessToken} setNotFoundPage={setNotFoundPage}  setPreventWorkflowRender={setPreventWorkflowRender} minimize={minimize}/>
                </div>
            </div>
            </div>):
            <>
            <Notfoundpage/>
            </>}
        </>
    );
}

export default AppDashboard;
