import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import 'Styles/DeveloperMode/Graph_content/CVSS-trends.css'

const AppCVSSTrend = () => {
  const [options] = useState({
    chart: {
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      labels: {
        colors: ['#FFCB2D', '#FF7917']
      },
      markers: {
        fillColors: ['#FFCB2D', '#FF7917']
      },
    },
    stroke: {
      curve: 'straight'
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      labels: {
        style: {
          colors: '#8e8da4',
        }
      }
    },
    yaxis: {
      tickAmount: 4,
      floating: false,
      labels: {
        style: {
          colors: '#8e8da4',
        },
        offsetY: -7,
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false
      }
    },
    colors: ['#25CD25'],
    fill: {
      opacity: 0.5
    },
    tooltip: {
      x: {
        format: "MMM",
      },
      fixed: {
        enabled: false,
        position: 'topRight'
      }
    },
    grid: {
      yaxis: {
        lines: {
          offsetX: -30
        }
      },
      padding: {
        left: 20
      },
    }
  });

  const [series] = useState([
    {
      name: 'CVSS-score',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ]);

  return (
    <div>
      <div className={`cvss-trend1`}> CVSS Trend</div>
      <div>
        <ReactApexChart options={options} series={series} type="area" height={260} style={{ marginLeft: '30px' }} />
      </div>
    </div>
  );
};

export default AppCVSSTrend;
