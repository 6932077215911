import React, { useEffect, useMemo, useState } from 'react';
// import { useParams } from 'react-router-dom';
import 'Styles/DeveloperMode/FlowDB/FlowDashboard.css';
import ListOfFlows from 'Components/DeveloperMode/FlowDB/ListOfFlows';
import ThreatStatus from 'Components/DeveloperMode/Graph_content/ThreatStatus';
import rightArrow from 'Images/Vector (2).svg'
import setting from 'Images/Sorting (1).svg'
import '@fontsource/dm-sans'
import CWEMatric from 'Components/DeveloperMode/Graph_content/CWE_matric';
import PaginationDots from 'Components/Pagination/PaginationDots';
import dataNotFound from 'Images/notDatafound.svg';
import bell_icon from 'Images/bell_icon.svg'
import ChoosePreference from 'Components/DeveloperMode/PopUpUtils/ChoosePreference';
// import { useWorkflowDetails } from '../../StateManagement/WorkflowDetailsContext';
// import { useNavigate } from 'react-router-dom';



const AppLevelDashboard = ({setErrorFlag, onRefreshToken,setNotFoundPage ,setPreventWorkflowRender,minimize}) => {
    const [activeItem, setActiveItem] = useState('11.20');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 3;
    const [displayItems, setDisplayItems] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    // const { app_id } = useWorkflowDetails();
    // const { flowId} = useWorkflowDetails();
    // const navigate = useNavigate();
    // if (!app_id && !flowId) {
    //     navigate('/')
    //   }
  
    const importThreatCount = (threatCount) => {
        setDisplayItems(threatCount > 0);
    }


    const handleItemClick = (item) => {
        setActiveItem(item);
    };


    const fetchData = useMemo(() => [
        { id: 1, text1: "Access Control", text2: "Ensure proper mechanisms are in place to prevent unauthorized access." },
        { id: 2, text1: "Data Encryption", text2: "Encrypt sensitive data both at rest and in transit for enhanced security." },
        { id: 3, text1: "Dependency Management", text2: "Regularly update and patch third-party libraries to avoid vulnerabilities." },
        { id: 4, text1: "Input Validation", text2: "Implement strong validation to prevent injection attacks and other threats." },
        { id: 5, text1: "Secure Design", text2: "Apply secure design principles to avoid weaknesses in the application architecture." },
        { id: 6, text1: "Security Configurations", text2: "Ensure security settings are applied consistently across all environments." },
        { id: 7, text1: "Security Testing", text2: "Perform regular security tests, including static and dynamic analysis, to identify risks." },
        { id: 8, text1: "Logging and Monitoring", text2: "Implement proper logging and monitoring to detect and respond to security incidents." },
        { id: 9, text1: "Security Awareness", text2: "Educate your team on security best practices, including awareness of common risks." },

    ], []);

    useEffect(() => {
        setCurrentPage(fetchData.slice(0, itemsPerPage));
        
    }, [fetchData, itemsPerPage]);


    const handlePageChange = (page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setCurrentPage(fetchData.slice(startIndex, endIndex));
    };


    return (
        <div className="flow-dashboard">

            <div className="row1">
                <div className="box">
                    <ThreatStatus  importThreatCount={importThreatCount} displayItems={displayItems} onRefreshToken={onRefreshToken} setNotFoundPage={setNotFoundPage} />
                </div>

                <div className="box">
                    <div className='flex-display1'>
                        <div id='about' >
                            About
                            <div id='app-name'>
                                {/* {appId} */}
                            </div>
                        </div>
                        <div id='update'>
                            Update
                        </div>

                    </div>
                    <div>
                        {!displayItems ?
                            <div className='data-not-found'>
                                <img src={dataNotFound} alt='data not found' />

                            </div> :
                            <div>
                                <div className='flex-display'>
                                    <div className={`flex-box ${activeItem === '11.20' ? 'active' : ''} `} onClick={() => handleItemClick('11.20')}>

                                        <div id='flex-box2'>
                                            11.20 hrs
                                        </div>
                                        previous run
                                    </div>
                                    <div className={`flex-box ${activeItem === '15.40 hrs' ? 'active' : ''}`} onClick={() => handleItemClick('15.40 hrs')}>

                                        <div id='flex-box2'>
                                            15.40 hrs
                                        </div>
                                        next scheduled run
                                    </div>
                                    <div className={`flex-box ${activeItem === 'V.01.23' ? 'active' : ''}`} onClick={() => handleItemClick('V.01.23')}>

                                        <div id='flex-box2'>
                                            V.01.23
                                        </div>
                                        version run
                                    </div>
                                </div>
                                <div className='flex-display'>

                                    <div className={`flex-box ${activeItem === '3rd September, 2023' ? 'active' : ''}`} onClick={() => handleItemClick('3rd September, 2023')}>

                                        <div id='flex-box2'>
                                            3rd September, 2023
                                        </div >
                                        version update
                                    </div>


                                    <div className={`flex-box ${activeItem === 'Sprint 07' ? 'active' : ''}`} onClick={() => handleItemClick('Sprint 07')}>

                                        <div id='flex-box2'>
                                            Sprint 07
                                        </div>
                                        next action plan
                                    </div>
                                    <div className={`flex-box ${activeItem === '4 members' ? 'active' : ''}`} onClick={() => handleItemClick('4 members')}>

                                        <div id='flex-box2'>
                                            4 members
                                        </div>
                                        team
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="row2">
                <div className="big-box" >
                    <ListOfFlows setErrorFlag={setErrorFlag} onRefreshToken={onRefreshToken} setOpenForm={setOpenForm} setNotFoundPage={setNotFoundPage}  setPreventWorkflowRender={setPreventWorkflowRender} minimize={minimize}/>
                </div>
                <div className="big-box">
                    <div>
                        {!displayItems ?
                            <div className='data-not-found'>
                                <p>CWE Metric</p>
                                <img src={dataNotFound} alt='data not found' id='cwe-data-found' />
                            </div> :
                            <div>
                                <CWEMatric  onRefreshToken={onRefreshToken} />
                            </div>
                        }
                    </div>

                    <div className={`flow-recommendation `}>
                        <div id='flow-recommendation'> Alerts & Recommendations
                            <img src={setting} alt='setting' />
                        </div>
                        <div id='space-recommendation'>
                            <div>
                                {!displayItems ?
                                    <div className='bell_icon'>
                                        <img src={bell_icon} alt='currently data not available' id='cwe-data-found' />
                                    </div> :
                                    <div>
                                        {currentPage && currentPage.length > 0 && currentPage.map((currentPage) => (
                                            <div className={`flow-suggestion `} key={currentPage.id}>{currentPage.text1}
                                                <p className={`flow-suggestion-box `}>{currentPage.text2}
                                                    <img src={rightArrow} alt='rightArrow' />
                                                </p>
                                            </div>
                                        ))}

                                        <PaginationDots currentPage1={currentPage} totalPages={Math.ceil(fetchData.length / itemsPerPage)} onPageChange={handlePageChange} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {openForm ? <ChoosePreference setOpenForm={setOpenForm} onRefreshToken={onRefreshToken} /> : ""}
            </div>
        </div>
    );
}

export default AppLevelDashboard;
