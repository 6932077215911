// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.json-object {
    padding-left: 25px;
    /* font-family:  monospace; */
    font-family: 'DM Sans';
    /* font-size: 12px; */
    /* border: 1px solid red; */
    
}

.json-entry {
    margin: 3px 1px 3px 9px;

}

.json-key {
    font-weight: normal;
    font-family: 'DM Sans';
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    
}

.json-value {
    /* font-family:  monospace; */
    color: #8BC7FF;
    font-size: 12px;
}

.attack-value{
    color: #25CD25;
}
.highlight-value {
    color: #FFCB2D;
    font-weight: bold;
}

/* .json-object{
    font-size: 1px;
} */




.collapse{
    cursor: pointer;
    width: 10px;
    padding-right: 5px;
}

.expand{
    cursor: pointer;
    height: 10px;
    padding-right: 5px;
}

.highlighted-object{
  box-shadow: 0 4px 8px rgba(0,0,0, 0.1); 
  border: 1px solid rgba(0, 0, 0, 0.1); 
  background-color: rgba(102, 102, 101, 0.2);
  padding: 10px;
  margin: 8px 0;
  border-radius: 6px; /* Rounded corners */
  transition: box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
  width: 100%;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/Components/DeveloperMode/ReportContainer/JsonViewer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,sBAAsB;IACtB,qBAAqB;IACrB,2BAA2B;;AAE/B;;AAEA;IACI,uBAAuB;;AAE3B;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,eAAe;IACf,eAAe;;AAEnB;;AAEA;IACI,6BAA6B;IAC7B,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;;GAEG;;;;;AAKH;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;AAEA;EACE,sCAAsC;EACtC,oCAAoC;EACpC,0CAA0C;EAC1C,aAAa;EACb,aAAa;EACb,kBAAkB,EAAE,oBAAoB;EACxC,4DAA4D,EAAE,uBAAuB;EACrF,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".json-object {\n    padding-left: 25px;\n    /* font-family:  monospace; */\n    font-family: 'DM Sans';\n    /* font-size: 12px; */\n    /* border: 1px solid red; */\n    \n}\n\n.json-entry {\n    margin: 3px 1px 3px 9px;\n\n}\n\n.json-key {\n    font-weight: normal;\n    font-family: 'DM Sans';\n    color: #fff;\n    font-size: 12px;\n    cursor: pointer;\n    \n}\n\n.json-value {\n    /* font-family:  monospace; */\n    color: #8BC7FF;\n    font-size: 12px;\n}\n\n.attack-value{\n    color: #25CD25;\n}\n.highlight-value {\n    color: #FFCB2D;\n    font-weight: bold;\n}\n\n/* .json-object{\n    font-size: 1px;\n} */\n\n\n\n\n.collapse{\n    cursor: pointer;\n    width: 10px;\n    padding-right: 5px;\n}\n\n.expand{\n    cursor: pointer;\n    height: 10px;\n    padding-right: 5px;\n}\n\n.highlighted-object{\n  box-shadow: 0 4px 8px rgba(0,0,0, 0.1); \n  border: 1px solid rgba(0, 0, 0, 0.1); \n  background-color: rgba(102, 102, 101, 0.2);\n  padding: 10px;\n  margin: 8px 0;\n  border-radius: 6px; /* Rounded corners */\n  transition: box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transitions */\n  width: 100%;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
