import React, { useEffect, useState } from 'react'
import 'Styles/DeveloperMode/AppDashboard/FlowsContainer.css';
import LeftSidebar from 'Components/DeveloperMode/LeftSidebar/LeftSidebar';
import 'Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import Navbar from 'Components/DeveloperMode/MainContent/Navbar';
import MinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/MinimizeLeftBar';
import All_reports from 'Components/DeveloperMode/ReportContainer/All_reports';
import ShowReport from 'Components/DeveloperMode/ReportContainer/ShowReport';
import close from 'Images/close.svg';
import ZoomInReport from 'Components/DeveloperMode/ReportContainer/ZoomInReport';
import back from 'Images/Previous.svg';
import ViewHtml from 'Components/DeveloperMode/ReportContainer/ViewHtml';
import DOMPurify from 'dompurify';
import {useNavigate } from 'react-router-dom';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';
import EditAttack from './EditAttack';

const ReportTab = ({ onLogout, onRefreshToken, minimize, setMinimize }) => {
    const [cweInfoName, setCWEInfoName] = useState('');
    const [showReport, setShowReport] = useState(false);
    const [reportID, setReportID] = useState(null);
    const [cweName, setCWEName] = useState('');
    const [application, setApplication] = useState('');
    const [flow, setFlow] = useState('');
    const [run, setRun] = useState('')
    const [zoomFlag, setZoomFlag] = useState(false);
    const [selectApiFromReport, setSelectApiFromReport] = useState(0);
    const [maxFilteredData, setMaxFilteredData] = useState([]);
    const [viewHtml, setViewHtml] = useState(false);
    const [htmlBody, setHtmlbody] = useState('');
    const [errorFlag,setErrorFlag]=useState(false);
    const navigate = useNavigate();
    const {showSnackbar}=useSnackbarContext();
    const [editAttack, setEditAttack] = useState(false);
    const [sendAttackRequest, setSendAttackRequest] = useState([]);
    const [sendAttackResponse, setSendAttackResponse] = useState([]);
    const [isCheckRequest,setIsCheckRequest]=useState(false);
    const clean = DOMPurify.sanitize(htmlBody);

    useEffect(() => {
      if (errorFlag) {
        navigate("/");
        showSnackbar(`The server is currently down for maintenance.`, {
          variant: 'error',
          className: 'snackbar-error',
          autoHideDuration: 2500,
        });
    
        // Reset the error flag 
        setErrorFlag(false);
      }
    }, [errorFlag, navigate, showSnackbar]);
    // Function to go back to All_reports
    const handleShowAllReports = () => {
        setShowReport(false);
    }


    const popup = {
        cwePopUp: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 900,
        },
        cwePopUpcontent: {
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
            width: '96%',
            // height:'96%',
            position: 'relative',
        },
        closeButton: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '5px'
        },
        closeImage: {
            cursor: 'pointer',
            width: '13px',
            height: '15px',
        },

    }

    return (

        <div className='appDashboard'>
            {/* {!zoomFlag ? */}
            <>
                <div className='sidebar'>
                    {minimize ? <LeftSidebar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <MinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}
                </div>
                <div className='content'>
                    <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
                        <Navbar minimize={minimize} cweInfoName={cweInfoName} />
                    </div>
                    <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>

                        <All_reports
                            onRefreshToken={onRefreshToken}
                            setCWEInfoName={setCWEInfoName}
                            setReportID={setReportID}
                            setShowReport={setShowReport}
                            setCWEName={setCWEName}
                            setApplication={setApplication}
                            setFlow={setFlow}
                            setRun={setRun}
                            setErrorFlag={setErrorFlag}
                        />

                        {showReport && (
                            <div style={popup.cwePopUp}>

                                <div style={popup.cwePopUpcontent}>
                                    
                                        <div>
                                            <div style={{ display: 'flex', gap: '10px', boxSizing: 'border-box', marginBottom: '-16px', marginLeft: '10px' }}>
                                                <img src={back} alt='backbutton' onClick={handleShowAllReports} style={{ cursor: 'pointer', width: '10px', height: '20px' }} />
                                                {cweName}
                                            </div>

                                        </div>
                                   
                                  
                                    <div style={popup.closeButton}>
                                        <img
                                            src={close}
                                            alt='close'
                                            style={popup.closeImage}
                                            onClick={handleShowAllReports}
                                        />

                                    </div>

                                    <ShowReport
                                        setZoomFlag={setZoomFlag}
                                        reportID={reportID}
                                        cweName={cweName}
                                        application={application}
                                        flow={flow}
                                        run={run}
                                        setSelectApiFromReport={setSelectApiFromReport}
                                        setMaxFilteredData={setMaxFilteredData}
                                        setViewHtml={setViewHtml}
                                        setHtmlbody={setHtmlbody} 
                                        setErrorFlag={setErrorFlag}
                                        setSendAttackRequest={setSendAttackRequest}
                                        setEditAttack={setEditAttack}
                                        setSendAttackResponse={setSendAttackResponse}
                                        setIsCheckRequest={setIsCheckRequest}
                                        />
                                </div>
                                {zoomFlag &&
                                    <>
                                        <ZoomInReport 
                                        setZoomFlag={setZoomFlag} 
                                        selectApiFromReport={selectApiFromReport} 
                                        maxFilteredData={maxFilteredData} 
                                        setMaxFilteredData={setMaxFilteredData} 
                                        setViewHtml={setViewHtml}
                                        setHtmlbody={setHtmlbody}
                                        setIsCheckRequest={setIsCheckRequest}
                                        setSendAttackRequest={setSendAttackRequest}
                                        setEditAttack={setEditAttack}
                                        setSendAttackResponse={setSendAttackResponse}/>
                                    </>
                                }
                                
                            </div>
                        )}

                    </div>
                    {viewHtml &&
                        <ViewHtml
                            htmlContent={clean}
                            setViewHtml={setViewHtml}
                        />
                    }
                     {editAttack &&
                <EditAttack
                    editAttack={editAttack}
                    setEditAttack={setEditAttack}
                    sendAttackRequest={sendAttackRequest}
                    sendAttackResponse={sendAttackResponse}
                    isCheckRequest={isCheckRequest} />
            }
                </div>

            </>
           
        </div>


    )
}

export default ReportTab;

