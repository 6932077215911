import React, { useState } from 'react';
import 'Styles/DeveloperMode/PopUpUtils/Calender.css';
import close from 'Images/close.svg'
import next from 'Images/Next.svg'
import previous from 'Images/Previous.svg'

const Calendar = ({ showCalender, setShowCalender }) => {
  // Get current date
  const currentDate = new Date();
  const [month, setMonth] = useState(currentDate.getMonth());
  const [year, setYear] = useState(currentDate.getFullYear());
  const [selectedDate, setSeletedDate] = useState([]);
  const [hour, sethour] = useState(null);
  const [min, setMin] = useState(null);
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, month, 1).getDay();

  // console.log("get day of month",new Date(year, month, 1).getDay())

  const renderDays = () => {
    const days = [];
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // start 1st date from corresponding day
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="empty-cell"></div>);
    }
    // Render days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      // Check if the current day matches the current date
      const isPastDates = ((year < currentYear) || ((year === currentYear) && (month < currentMonth || (month === currentMonth && i < currentDay))))

      const isSelectedDate = selectedDate.includes(i);
      const isCurrentDate = i === currentDay && month === currentDate.getMonth() && year === currentDate.getFullYear();
      // Add a special class to highlight the current date
      const classNames = `day ${isCurrentDate ? 'current-date' : ''} ${isSelectedDate ? 'selected-date' : ''} ${isPastDates ? 'past-date' : ''}`;

      days.push(<div key={i} className={classNames} onClick={() => handleSelectDate(i)} style={{ cursor: 'pointer' }}>{i}</div>);
    }
    return days;
  };

  const handleSelectDate = (day) => {
    if (selectedDate.includes(day)) {
      setSeletedDate(selectedDate.filter(date => date !== day))
    }
    else {
      setSeletedDate([...selectedDate, day])
    }
  }



  const renderHours = () => {
    const hours = [];
    for (let i = hour - 2; i <= hour + 2; i++) {
      if (i >= 0 && i <= 23) {
        hours.push(
          <div key={i} className={`hour ${hour === i ? 'selected-hour' : ''}`} onClick={() => handleHourSelect(i)} style={{ cursor: 'pointer' }}>
            {i < 10 ? `0${i} ` : i}<hr></hr>
          </div>
        );
      }
    }
    return hours;
  };

  const handleHourSelect = (hour) => {
    console.log("Selected hour:", hour);
    sethour(hour)
  };



  const renderMinutes = () => {
    const minutes = [];
    for (let i = min - 2; i <= min + 2; i += 1) {
      if (i >= 0 && i <= 59) {
        minutes.push(
          <div key={i} className={`minute ${min === i ? 'selected-minute' : ''}`} onClick={() => handleMinuteSelect(i)} style={{ cursor: 'pointer' }}>
            {i < 10 ? `0${i}` : i}<hr></hr>
          </div>
        );
      }
    }
    return minutes;
  };


  
  


  const handleMinuteSelect = (minute) => {
    console.log("Selected minute:", minute);
    setMin(minute)
  };

  const handleScroll = (event) => {
    if (event.deltaY < 0) {
      sethour((prevHour) => (prevHour - 1 + 24) % 24);
   
    } else {
      sethour((prevHour) => (prevHour + 1) % 24);
 
    }
  };

  const handleScroll1 = (event) => {
    if (event.deltaY < 0) {
  
      setMin((prevMin) => (prevMin - 1 + 60) % 60);
    } else {
   
      setMin((prevMin) => (prevMin + 1) % 60);
    }
  };

  const options = [
    "daily",
    "weekly",
    "custom"
  ]

  return (
    <div className='blur-background-calender'>
      <div className='main-calender'>
        <div className={`calendar ${showCalender ? 'show' : ''}`}>
          <div className='calender-head'>
            <div className='sc-run'>
              Schedule Run
            </div>
            <button className='close' onClick={() => setShowCalender(false)}  >
              <img src={close} alt='close0' />
            </button>
          </div>
          <div className='input-calender'>
            <p>Enter run name</p>
            <input
              type='text'
              id='runtest'
              name='runtest'
              placeholder='application name'
            />
          </div>
          <div className='repeat'>
            <label for='repeat'>Repeat</label>
            <input className='checkbox' type='checkbox' id='repeat' name='repeat' value={"repeat"} />
          </div>
          <div className='input-calender'>
            <p>Repeat interval</p>


            <select>
              <option>weekly /daily/ custom</option>
              {options.map((option, idx) => {
                return (
                  <option key={idx}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='complete-calender'>
            <div className="calender-month">

              <p id='months'>{`${new Date(year, month).toLocaleString('default', { month: 'long' })} ${year}`}</p>
              <div className='calender-sc'>
                <div id='c-date'>Current Date</div>
                <div id='s-date'>Schedule Date</div>
                <button onClick={() => setMonth(month - 1)}><img src={previous} alt='previous' /></button>
                <button onClick={() => setMonth(month + 1)}><img src={next} alt='next' /></button>
              </div>

            </div>
            <hr></hr>
            <div className='day-hr-mim'>
              <div className="days">

                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                  <div key={day} className="day-label">{day}</div>
                ))}
                {renderDays()}

              </div>
              
              <div className='hr-min'>
                <div className="hours">
                  <p>Hours</p>
                  <div className='time-block' onWheel={handleScroll}>
                    {renderHours()}
                  </div>

                </div>
                <div className="minutes">
                  <p>Mins</p>
                  <div  className='time-block'onWheel={handleScroll1}>
                    {renderMinutes()}
                  </div>

                </div>
              </div>
            </div>
            
          </div>

          <button className='btn-sc' >Schedule</button>


        </div>
      </div>
    </div>
  );
};

export default Calendar;
