import React, { useEffect, useState } from 'react';
import ApplicationLeftBar from 'Components/DeveloperMode/ApplicationDashboard/ApplicationLeftBar';
import ApplicationNavbar from 'Components/DeveloperMode/ApplicationDashboard/ApplicatonNavbar';
import ListOfApplication from 'Components/DeveloperMode/ApplicationDashboard/ListOfApplication';
import ApplicationMinimizeLeftBar from 'Components/DeveloperMode/LeftSidebar/ApplicationMinimizeLeftBar';
import 'Styles/DeveloperMode/AppDashboard/AppDashboard.css';
import {useNavigate } from 'react-router-dom';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';

const DeveloperComponent = ({ onLogout, onRefreshToken, setAllAppNames, allAppNames, minimize, setMinimize }) => {
const [errorFlag,setErrorFlag]=useState(false);
const navigate = useNavigate();
const {showSnackbar}=useSnackbarContext();

useEffect(() => {
  if (errorFlag) {
    navigate("/");
    showSnackbar(`The server is currently down for maintenance.`, {
      variant: 'error',
      className: 'snackbar-error',
      autoHideDuration: 2500,
    });

    // Reset the error flag 
    setErrorFlag(false);
  }
}, [errorFlag, navigate, showSnackbar]);

// console.log(errorFlag,"errorflag")
  return (
    <div className='appDashboard'>
      <div className='sidebar'>
        {minimize ? <ApplicationLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} /> : <ApplicationMinimizeLeftBar onLogout={onLogout} setMinimize={setMinimize} minimize={minimize} />}

      </div>
      <div className='content'>
        <div className={`navbar-container ${!minimize ? 'minimized' : ''}`}>
          <ApplicationNavbar />
        </div>
        <div className={`mainContent ${!minimize ? 'minimized' : ''}`}>
          <ListOfApplication setErrorFlag={setErrorFlag} onRefreshToken={onRefreshToken} setAllAppNames={setAllAppNames} allAppNames={allAppNames} />
        </div>
      </div>
    </div>
  );
};

export default DeveloperComponent;







