import React from 'react';
import 'Styles/DeveloperMode/AppDashboard/MainContent.css';

import AppLevelDashboard from 'Components/DeveloperMode/FlowDB/AppLevelDashboard';


const MainContent = ({setErrorFlag,onRefreshToken,setNotFoundPage,setPreventWorkflowRender,minimize}) => {
    return (
        <div className="main-container">
            <div className="content-container">
              <AppLevelDashboard setErrorFlag={setErrorFlag} onRefreshToken={onRefreshToken} setNotFoundPage={setNotFoundPage} setPreventWorkflowRender={setPreventWorkflowRender} minimize={minimize} />
            </div>
        </div>
    );
}

export default MainContent;
