
import React, { useEffect, useState } from 'react';
import 'Styles/DeveloperMode/ReportGeneration/All_reports.css';
import { useWorkflowDetails } from 'Components/StateManagement/WorkflowDetailsContext';
import vector from 'Images/Vector.svg';
import sorting from 'Images/Sorting (1).svg';
import dataNotFound from 'Images/notDatafound.svg';
import ChoosePreference from 'Components/DeveloperMode/PopUpUtils/ChoosePreference';
import { useNavigate } from 'react-router-dom';
import arrowUp from 'Images/arrowup.svg';
import downArrow from 'Images/arrowDown.svg';
import { useSnackbarContext } from 'Components/StateManagement/SnackbarContext';


const All_reports = ({setErrorFlag, onRefreshToken, setReportID, setShowReport ,setCWEName,setApplication,setFlow,setRun }) => {

    const [workflows, setWorkflows] = useState([]);
    const [allRunsList, setAllRunsList] = useState([]);
    const { runId, setRunId } = useWorkflowDetails();
    const [flowList, setFlowList] = useState();
    const [threatCount, setThreatCount] = useState();
    const { flowId, setFlowId } = useWorkflowDetails();
    const { flowName, setFlowName } = useWorkflowDetails();
    const [displayItems, setDisplayItems] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [runlist, setRunList] = useState([]);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_URL;
    const { app_id, setApp_id } = useWorkflowDetails();
    const [accessTokenUpdated, setAccessTokenUpdated] = useState('');
    const localToken = localStorage.getItem('accessToken');
    const [applications, setApplications] = useState([]);
    const [selectedAppId, setSelectedAppId] = useState(null);
    const [selectedFlowId, setSelectedFlowId] = useState(null);
    const [selectedRunId, setSelectedRunId] = useState(null);
    const [reports, setReports] = useState([]);
    const [expandedID, setExpandedId] = useState(null);
    const [selectedReportId, setSelectedReportId] = useState(null);
    const [selectedCWE, setSelectedCWE] = useState(null);

    const [expandedCWE, setExpandedCWE] = useState(null);
    const [cweObj, setCweObj] = useState({});
    const {showSnackbar}=useSnackbarContext();

    useEffect(() => {
        const storedApp_id = localStorage.getItem('app_id');
        if (storedApp_id) {
            setApp_id(storedApp_id);
        }
    }, [setApp_id]);

    useEffect(() => {
        fetchApplicationList();
    }, []);

    const fetchApplicationList = async () => {
        try {
            const response = await fetch(`${apiUrl}/resources/apps`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                setApplications(data);
                // setDatafetch(true);
            }
            else if (response.status === 400) {

                showSnackbar(`Bad Request`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }
              else if (response.status === 500) {
        
                showSnackbar(`Internal Server Error`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            }  else if (response.status === 404) {
                showSnackbar(`Page Not Found`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })

            }
        } catch (error) {
            console.log('Failed to fetch list of application', error);
            if (error.message.includes("ERR_CONNECTION_REFUSED") || error.message.includes("Failed to fetch")) {
                setErrorFlag(true);
            }
        }
    }

    // useEffect(() => {
    //         fetchAllFlows();
    // }, []);
    useEffect(() => {
        if (selectedAppId) {
            fetchAllFlows();
        }
    }, [selectedAppId]);

    useEffect(() => {
        if (accessTokenUpdated) {
            if (app_id) {
                fetchAllFlows();
            }
        }
    }, [accessTokenUpdated]);

    const fetchAllFlows = async () => {
        try {
            const response = await fetch(`${apiUrl}/resources/apps/${selectedAppId}/flows`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setWorkflows(data);
                // setDatafetch(true);

                if (data && data.length > 0) {
                    const hasFlowName = data.some(workflow => !!workflow.flow_name);
                    setDisplayItems(hasFlowName);
                }

                if (data && data.length > 0) {
                    const flownames = data.map(flow => flow.flow_name);
                    setFlowName(flownames);

                    const flowCount = flownames.length;
                    const threatCount = data.map(threat => threat.total_threat_count);
                    const totalThreatCount = threatCount.reduce((acc, count) => acc + count, 0);
                    // const flow_id = data.map(flow_id => flow_id._id);
                    // setFlowId(flow_id);
                    setFlowList(flowCount);
                    setThreatCount(totalThreatCount);
                }
            }
            else if (response.status === 400) {

                showSnackbar(`Bad Request`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }
              else if (response.status === 500) {
        
                showSnackbar(`Internal Server Error`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }
               else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            } else if (response.status === 404) {
                showSnackbar(`Page Not Found`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })

            }
        }
        catch (error) {
            console.error("error in fetching workflows of application", error);
            if (error.message.includes("ERR_CONNECTION_REFUSED") || error.message.includes("Failed to fetch")) {
                setErrorFlag(true);
            }
        }
    };

    useEffect(() => {
        if (flowId && selectedAppId) {
            fetchAllRuns();
        }
    }, [selectedAppId, flowId]);

    useEffect(() => {
        if (accessTokenUpdated) {
            fetchAllRuns();
        }
    }, [accessTokenUpdated]);

    const fetchAllRuns = async () => {
        if (!selectedAppId || !flowId) return;
        try {
            const url = `${apiUrl}/resources/apps/${selectedAppId}/flows/${flowId}/runs`;
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            });
            if (response.ok) {
                console.log("hello runs")
                const data = await response.json();
                // setAllRunsList(Array.isArray(data) ? data : []);
                setAllRunsList(data);
                setRunId(data._id);
                // console.log(JSON.stringify(data))
                if (data && data.length > 0) {
                    const runs = data.map(runs => runs.run_name);
                    setRunList(runs);
                }
            }
            else if (response.status === 400) {

                showSnackbar(`Bad Request`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }
              else if (response.status === 500) {
        
                showSnackbar(`Internal Server Error`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }
               else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            }
        }
        catch (error) {
            console.log("error in fetching list of runs", error);
            if (error.message.includes("ERR_CONNECTION_REFUSED") || error.message.includes("Failed to fetch")) {
                setErrorFlag(true);
            }
        }
    };

    useEffect(() => {
        if (selectedAppId && flowId && runId) {
            fetchReports();
        }

    }, [selectedAppId, flowId, runId]);

    useEffect(() => {
        if (accessTokenUpdated) {
            fetchReports();
        }
    }, [accessTokenUpdated]);

    const fetchReports = async () => {
        if (!runId || !selectedAppId || !flowId) return;
        try {
            const response = await fetch(`${apiUrl}/reports/apps/${selectedAppId}/flows/${flowId}/runs/${runId}/cwe`, {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                }
            });
            if (response.ok) {
                const data = await response.json();

                // console.log(JSON.stringify(data), "data")

                setReports(data)
            }
            else if (response.status === 400) {

                showSnackbar(`Bad Request`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }
              else if (response.status === 500) {
        
                showSnackbar(`Internal Server Error`, { variant: 'error', className: 'snackbar-error',  autoHideDuration:2500 })
        
              }
               else if (response.status === 401) {
                const result = await onRefreshToken();
                if (result === "success") {
                    setAccessTokenUpdated(localStorage.getItem('accessToken'));
                } else {
                    navigate('/');
                }
            }  else if (response.status === 404) {
                showSnackbar(`Page Not Found`, { variant: 'error', className: 'snackbar-error', autoHideDuration: 2500 })

            }

        } catch (error) {
            console.log("Failed to fetch reports", error);
            if (error.message.includes("ERR_CONNECTION_REFUSED") || error.message.includes("Failed to fetch")) {
                setErrorFlag(true);
            }
        }
    }

    const handleAppClick = (app_id) => {
        setSelectedAppId(app_id);
        setSelectedFlowId(null);
        setSelectedRunId(null);
        setExpandedId(null);
        setSelectedReportId(null)
        setReports([]);
    };


    const renderApplications = () => {
        return applications && applications.map((app, index) => (
            <ul key={index}>
                <li className={`appsNameReport ${selectedAppId === app._id ? 'selected' : ''}`} onClick={() => handleAppClick(app._id)}>
                    <div className='bulletpointApp'> {app.app_name}</div>
                    <div>
                        <img src={vector} alt='sorting' className='arrowApp' />
                    </div>
                </li>
            </ul>
        ));
    };



    const handleWorkflowClick = (fl_id, fl_name) => {
        setFlowId(fl_id);
        setFlowName(fl_name);
        setSelectedFlowId(fl_id);
        setSelectedRunId(null);
        setExpandedId(null);
        setSelectedReportId(null)
    };
    const renderflowName = () => {
        return workflows && workflows.map((workflow, index) => (
            <ul key={index}>
                <li className={`flowsNameReport ${selectedFlowId === workflow._id ? 'selected' : ''}`} onClick={() => handleWorkflowClick(workflow._id, workflow.flow_name)}>
                    <div>
                        <div className='bulletpointR'> {workflow.flow_name} </div>
                        <p id='role-based-scan-1R'> Admin | Regular user</p>
                    </div>
                    <div className='threat_countR'>
                        <div className='keyarrowR'>
                            <img src={vector} alt='sorting' />
                        </div>
                    </div>
                </li>
            </ul>
        ));
    };

    const handleRunClick = (run_id, run_name) => {
        // console.log(id)
        setRunId(run_id)
        setSelectedRunId(run_id)
        setReportID(null)
        // setExpandedId(null);
        setSelectedReportId(null)
        if (run_id === expandedID) {
            setExpandedId(null);
        } else {
            setExpandedId(run_id);
        }

    }

    // console.log(expandedID,"hellllloo")
    const renderRuns = () => {
        return allRunsList && allRunsList.map((runs, index) => (
            <ul key={index} >
                <li className={`runs-Name-report ${selectedRunId === runs._id ? 'selected' : ''}`} onClick={() => handleRunClick(runs._id, runs.run_name)}>

                    <div>
                        <div className='bulletpoint_run-report'>
                            <div>
                                {expandedID === runs._id ?
                                    <div > <img src={downArrow} alt='down' /></div>
                                    : <div ><img src={arrowUp} alt='up' /></div>}
                            </div>
                            <div style={{ marginBottom: '-20px' }}>{runs.run_name}</div>
                        </div>
                        <p id='role-based-scan-2'> Manual{<>&nbsp;</>} /{<>&nbsp;</>} New filter added</p>
                    </div>
                    {/* <div className='threat_countR' >
                        <div className='keyarrowR' style={{ marginLeft: '-5px', marginTop: '0px' }}>
                            <img src={vector} alt='sorting' />
                        </div>
                    </div> */}

                </li>
                <div  >
                    {
                        expandedID === runs._id && <div>{renderCWElist()}</div>
                    }
                </div>

            </ul>
        ))
    }

    const handleCWEClick = (id) => {
        setSelectedReportId(id);
        setReportID(id);
    };

    const handleCWEName = (name) => {
        setSelectedCWE(name);
        setCWEName(name);
        setShowReport(true);
        setExpandedCWE((prev) => (prev === name ? null : name));
        setApplication(selectedAppId);
        setFlow(flowId);
        setRun(runId);
    };

    const renderCWElist = () => {
        return (
            reports &&
            reports.map((report, index) => (
                <ul key={index}>
                    <li
                        className="cwe-Name-report"
                        onClick={() => handleCWEClick(report._id)}
                    >
                        <div className="bulletpoint_cwe-report">
                            <ul className="cweGrid">
                                { Array.isArray(report.report_info) &&
                                    report.report_info.map((cweName, idx) => (
                                        <li
                                            key={idx} cweName
                                            className={`cweName ${selectedCWE === cweName ? 'selected' : ''}`}
                                            onClick={() => handleCWEName(cweName)}
                                        >
                                            {cweName}


                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </li>
                </ul>
            ))
        );
    };

    return (
        <div>
            <div className='report-container-all'>

                <div className='row-two'>
                    <div className='apps-column'>
                        <div className='header'>
                            <div id='appListReport'>
                                <div>Applications</div>
                                <img src={sorting} alt='sorting' />
                            </div>
                        </div>
                        { applications && applications.length > 0 ? renderApplications() : <p>No applications found</p>}
                    </div>
                    <div className='flow-row-two'>
                        <div className='flow_buttonR'>
                            <div id='flow_textR'>Workflows</div>
                            <div>
                                <img src={sorting} alt='sorting' style={{ marginRight: '5px' }} />
                            </div>
                        </div>

                        <div>
                            {workflows && workflows.length > 0 ?
                                <div className='listFlow'>{renderflowName()}</div> :
                                <div >
                                    <img src={dataNotFound} alt='data not found' style={{ marginTop: '100px' }} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='sub-row'>

                        <div id='coltwo-rowtwo'>
                            <div id='runlistR'>
                                <div>Runs</div>
                                <img src={sorting} alt='sorting' />
                            </div>
                            {
                              allRunsList &&  allRunsList.length > 0 ? <div>{renderRuns()}</div> : <img src={dataNotFound} alt='data not found' style={{ marginTop: '100px' }} />
                            }

                        </div>
                    </div>

                </div>
            </div>
            {openForm ? <ChoosePreference setOpenForm={setOpenForm} /> : ""}
        </div>
    );
};

export default All_reports;